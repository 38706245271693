export const options = {
  AZ: {
    sloqan: "Sərfəli yol get!",
    from: "Haradan",
    to: "Haraya",
    language: "Dil",
    download: "Tətbiqi endir!",
    infoPart1: "Aşağı qiymətlərlə sizin xidmətinizdəyik",
    infoPart2: "Bizə etibar edə bilərsiniz",
    infoPart3: "Sürüşdürün, tapın, click edin və gedin!",
    infoDesc1:
      "Hara getməyinizdən asılı olmayaraq, aşağı qiymətlərlə geniş istiqamətlərimiz və marşrutlarımızdan mükəmməl səyahəti tapın.",
    infoDesc2:
      "Biz üzvlərimizin və tərəfdaşlarımızın hər biri ilə tanış olmaq üçün vaxt ayırırıq. Biz rəyləri, profilləri və şəxsiyyət vəsiqələrini yoxlayırıq ki, kiminlə səyahət etdiyinizi biləsiniz və təhlükəsiz platformamızda rahat şəkildə səyahətinizi sifariş edə bilərsiniz.",
    infoDesc3:
      "Səyahət sifariş etmək heç vaxt asan olmayıb! Mükəmməl texnologiya ilə təchiz edilmiş sadə proqramımız sayəsində bir neçə dəqiqə ərzində sizə yaxın bir səyahət sifariş edə bilərsiniz.",
    sloqan2: "Birlikdə yol get, xərcini azalt!",
    appOnPlatforms: "Gezz tətbiqi IOS və Android platformalarında.",
    address: "Ünvan:",
    addressDetails: "Bakı, Islam Abışov, 104",
    phone: "Əlaqə nömrəsi:",
    phoneDetails: "+994 55 254 40 40",
    email: "Email:",
    emailDetails: "info@gezz.az",
    copyright1: "© 2024 Bütün Müəllif Hüquqları Gezz-ə məxsusdur",
    copyright2: "Məxfilik Siyasəti",
    copyright3: "Qaydalar və Şərtlər",
    faq: "FAQ",
    howToUseIt: "Necə istifadə etməli?",
    whatIs: "Gezz nədir?",
    aboutUs: "Haqqımızda",
    blog: "Blog",
    actions: "Fəaliyyətlər",
    restourants: "Restoranlar",
    otels: "Otellər",
    travels: "Gedişlər",
    usersThrouts: "İstifadəçilərimizin düşüncələri",
    feedbacks: [
      {
        id: 1,
        stars: 5,
        date: "17 Yan 2024",
        desc: "Əla ideyadır və proqramdır. Məhşurlaşsa insanlar üçün böyük asanlıq olar. İstər sərnişin istər sürücü.",
        name: "Simral",
        title: "item #1",
      },
      {
        id: 2,
        stars: 5,
        date: "7 Dek 2023",
        desc: "Hərkəs istifadə etsin.hem sürücüyə həmdə yol istifadəçisinə sərf etsin.",
        name: "Cavid Mirzeyev",
        title: "item #1",
      },
      {
        id: 3,
        stars: 5,
        date: "14 Yan 2023",
        desc: "Çox Super Harasa Gedəndə 20 manat əvəzinə 5 manat verirsen yol yoldaşlıqı",
        name: "Guleliyev İzzet",
        title: "item #1",
      },
      {
        id: 4,
        stars: 5,
        date: "17 Yan 2024",
        desc: "Əla ideyadır və proqramdır. Məhşurlaşsa insanlar üçün böyük asanlıq olar. İstər sərnişin istər sürücü.",
        name: "Simral",
        title: "item #1",
      },
      {
        id: 5,
        stars: 5,
        date: "7 Dek 2023",
        desc: "Hərkəs istifadə etsin.hem sürücüyə həmdə yol istifadəçisinə sərf etsin.",
        name: "Cavid Mirzeyev",
        title: "item #1",
      },
      {
        id: 6,
        stars: 5,
        date: "14 Yan 2023",
        desc: "Çox Super Harasa Gedəndə 20 manat əvəzinə 5 manat verirsen yol yoldaşlıqı",
        name: "Guleliyev İzzet",
        title: "item #1",
      },
    ],
    yourSecurityTile: "Təhlükəsizliyiniz bizim üçün çox vacibdir",
    yourSecurityDesc:
      "Gezz-da biz platformamızı mümkün qədər təhlükəsiz etmək üçün çox çalışırıq. Ancaq biz sizə fırıldaqlardan necə yayınmalı olduğunuzu və baş verdikdə onları necə bildirmək lazım olduğunu  bilmənizi istəyirik. Təhlükəsizliyinizi qorumağa kömək etmək üçün məsləhətlərimizə əməl edin.",
    yourSecurityMore: "Daha çoxunu öyrən",
    aboutTile: "Gezz Haqqımızda",
    aboutDesc: [
      "Gezz Səyahət platformasıdır.",
      "Siz burada müxtəlif rayonlara gedən istifadəçiləri tapıb, birlikdə səyahət edə və yol xərcini bölüşə bilərsiniz.",
      "Müxtəlif şəhərlərdəki Restoran/Otel/Əyləncləri Gezz vasitəsilə kəşf edə bilərsiniz.",
      "Səyahət üçün maşın lazımdırsa, Gezz tətbiqində kirayə maşınları incələyə bilərsiniz.",
    ],

    aboutMore: "Tətbiqi endirin, səyahətdən zövq alın!",
    carPoolingTitle: "Carpooling birlikdə hərəkət etmək deməkdir 🌱",
    carPoolingDesc:
      "Fərdi üstünlüklərinizdən əlavə, siz mobilliyi daha sadə və ağıllı etmək üçün tədbirlər görmüş olursunuz. Gezz yarandığı gündən CO2 emissiyasının qarşısını alır:",
    carPoolingDesc2: "Gezz ilə yolunuzu bölüşün və ekoloji təsirinizi azaldın",
    carPoolingButton: "Gezz-i Endir",
    carPoolingCards: [
      {
        icon: "🌲",
        desc: "Hər gün ağac əkmək deməkdir",
      },
      {
        icon: "💧",
        desc: "İstehlak edilməmiş içməli su deməkdir",
      },
      {
        icon: "🕝",
        desc: "1 Azərbaycanlının günlərlə istehlakına bərabərdir",
      },
    ],
    howItsWorkTitle: "Necə işləyir?",
    howItsWorkDesc:
      "Yol paylaşma platformasından daha çox, Gezz yenilikçi səyahət proqramıdır. Səfərlərinizi bölüşməyə, son anda və ya əvvəlcədən təşkil etməyinizə imkan verir. Bununla yanaşı səyahətlərinizdə otel, restoran, əyləncə kimi alternativləri tapmağınıza kömək edir",
    howItsWorkCards: [
      {
        number: "01",
        title: "Gezz tətbiqini yükləyin",
        description:
          "Gezz sizə vərdişlərinizə uyğun səyahət təkliflərini tapmağın qayğısına qalır, heç nə etmək lazım deyil! Karos sizi başa düşür və vərdişləriniz üçün sizə ən uyğun carpoolers təklif edir.",
      },
      {
        number: "02",
        title: "Sizə ən uyğun olan təklifi seçin",
        description:
          "İstər yolçu olaraq sürücülərin, istərsə də sürücü olaraq yolçuların elanlarına istək göndərə bilərsiniz.",
      },
      {
        number: "03",
        title: "2 kliklə təsdiq edin!",
        description:
          "Sizə uyğun gedişi seçin ve pulsuz rezerv edin. Tətbiq daxili mesajlaşma, qiymət razılaşması, təçili gedişlərdə önə çıxarmaq kimi daha bir çox funksiyalardan da yararlana bilərsiniz.",
      },
    ],
    everyDayGrow: "Hər gün böyüyən topluluq",
    usersCountName: "istifadəçi",
    advantagesTitle: "Üstünlüklərinizi kəşf edin",
    advantagesDesc:
      "Gezz ilə, vaxta və pula qənaət etməyə, yeni insanlar tanımağa, ətraf mühitin qorunmasına imkan yaradırıq!",
    advantagesCards: [
      {
        person: "Yolçu",
        desc: "Avtobus qiymətinə maşın rahatlığı ilə gedin 🚌",
        price: "0",
        currency: "AZN",
        priceDesc: "dan başlayan qiymətlərlə",
        more: "Daha çox üstünlüklər >",
        image: "https://gezz.az/assets/firstImage.png",
      },
      {
        person: "Sürücü",
        desc: "Benzin pulunuza qənaət edin 💸",
        price: "+ 200",
        currency: "AZN",
        priceDesc: "qədər ekstra qazanc",
        more: "Daha çox üstünlüklər >",
        image: "https://gezz.az/assets/secondImage.png",
      },
    ],
    DivisPartTitle1: "Yolu birlikdə get",
    DivisPartTitle2: "və ",
    DivisPartTitle3: "Qənaət et",
    DivisPartDesc: "Rahat, sərfəli və əyləncəli yol getmək üçün 1 No-li tətbiq",
    DivisPartButton: "Tətbiqi endir",
    searchTypes: [
      {
        id: 0,
        name: "Gedişlər",
        image: [
          "https://gezz.az/assets/riderWhite.svg",
          "https://gezz.az/assets/riderBlack.svg",
        ],
      },
      {
        id: 1,
        name: "Maşınlar",
        image: [
          "https://gezz.az/assets/carWhite.png",
          "https://gezz.az/assets/carBlack.png",
        ],
      },
      {
        id: 2,
        name: "Restoranlar",
        image: [
          "https://gezz.az/assets/restaurantWhite.svg",
          "https://gezz.az/assets/restaurantBlack.svg",
        ],
      },
      {
        id: 3,
        name: "Otellər",
        image: [
          "https://gezz.az/assets/otelWhite.svg",
          "https://gezz.az/assets/otelBlack.svg",
        ],
      },
      {
        id: 4,
        name: "Fəaliyyətlər",
        image: [
          "https://gezz.az/assets/activityWhite.svg",
          "https://gezz.az/assets/activityBlack.svg",
        ],
      },
    ],
    search: "Axtar",
    searchNames: [
      "Gediş və ya şəhər axtarın",
      "Maşın axtarın",
      "Restoran və ya şəhər axtarın",
      "Otel və ya şəhər axtarın",
      "Fəaliyyət və ya şəhər axtarın",
    ],
    searchNamesNotFound: [
      "Təəssüf ki, axtarışa uyğun gediş tapılmadı.",
      "Təəssüf ki, axtarışa uyğun maşın tapılmadı.",
      "Təəssüf ki, axtarışa uyğun restoran tapılmadı.",
      "Təəssüf ki, axtarışa uyğun otel tapılmadı.",
      "Təəssüf ki, axtarışa uyğun fəaliyyət tapılmadı.",
    ],
    restourantsCards: [
      {
        id: "90fa6dae-ce55-4e0d-9ab0-2f437aef5e88",
        name: "Elite Restoran",
        city: 69,
        phone: "+994514305758",
        latitude: 40.8379832,
        longitude: 46.034589,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10839982.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10583026.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10547512.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10818466.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:22.455Z",
        updatedAt: "2024-04-22T10:49:22.455Z",
      },
      {
        id: "db211c28-331a-4e8b-97a1-0754b7409f76",
        name: "Şirxan Restoranı",
        city: 19,
        phone: "+994506838552",
        latitude: 40.6693287,
        longitude: 45.823086599999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10732543.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10296600.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10702488.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10174414.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:24.826Z",
        updatedAt: "2024-04-22T10:49:24.826Z",
      },
      {
        id: "083b4066-5970-46a2-9a04-12c450f89b89",
        name: "Narın Qala",
        city: 68,
        phone: "+994505655708",
        latitude: 40.9243449,
        longitude: 46.160612,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10480283.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10613888.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10858569.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10043608.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:24.978Z",
        updatedAt: "2024-04-22T10:49:24.978Z",
      },
      {
        id: "25aa4399-e9bf-4bae-81eb-d3eb13a9ba05",
        name: "Qala Restoranı",
        city: 69,
        phone: "+994503105711",
        latitude: 40.794785399999995,
        longitude: 46.079800399999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10291408.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10896051.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10741049.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10657594.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:26.213Z",
        updatedAt: "2024-04-22T10:49:26.213Z",
      },
      {
        id: "6c0da42d-9ae7-45f0-8685-9b22bcb953e8",
        name: "Topçu Restoran",
        city: 35,
        phone: "+994552898999",
        latitude: 40.8811574,
        longitude: 48.069077899999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10747024.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10760497.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10557546.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10350139.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:26.635Z",
        updatedAt: "2024-04-22T10:49:26.635Z",
      },
      {
        id: "0fcf41c7-4020-4d2e-8946-30ad28eafa35",
        name: "Melham restoranı",
        city: 67,
        phone: "+994508806060",
        latitude: 40.6888971,
        longitude: 48.6377373,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10720851.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10285355.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10342549.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10702998.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:26.914Z",
        updatedAt: "2024-04-22T10:49:26.914Z",
      },
      {
        id: "fca43cc3-766f-4a3d-aa97-63358930691e",
        name: "Green Restoran",
        city: 0,
        phone: "+994504605759",
        latitude: 40.0387139,
        longitude: 47.4545495,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10226491.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10607073.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10678683.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10875018.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:27.540Z",
        updatedAt: "2024-04-22T10:49:27.540Z",
      },
      {
        id: "a83a75ad-4ad4-4f91-95e6-fd73d811a71f",
        name: "Mountain Breeze",
        city: 44,
        phone: "+994519993999",
        latitude: 41.2324722,
        longitude: 48.3039133,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10428679.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10221857.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10028030.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10215119.jpeg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:28.225Z",
        updatedAt: "2024-04-22T10:49:28.225Z",
      },
      {
        id: "43d4c8ca-f3e7-423d-a31a-5559a722e610",
        name: "Istanbul Restoran Qazax",
        city: 40,
        phone: "+994708005959",
        latitude: 41.0900585,
        longitude: 45.358627999999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10040458.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10378458.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10134297.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10315137.jpeg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:28.373Z",
        updatedAt: "2024-04-22T10:49:28.373Z",
      },
      {
        id: "66e51461-98b9-4eea-bc11-f751d900f19f",
        name: "Çənlibel Restoran",
        city: 27,
        phone: "+994517172222",
        latitude: 41.4855261,
        longitude: 48.7762076,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10422249.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10493813.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10336291.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10191671.jpeg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:29.271Z",
        updatedAt: "2024-04-22T10:49:29.271Z",
      },
      {
        id: "437cf249-d8c8-4d96-b408-4ba92350b0e8",
        name: "Abqora Restoranı",
        city: 67,
        phone: "+994502950212",
        latitude: 40.6450672,
        longitude: 48.5842208,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10424984.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10277150.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10430604.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10257791.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:30.329Z",
        updatedAt: "2024-04-22T10:49:30.329Z",
      },
      {
        id: "1a0fa0aa-20c3-4a74-a51f-279bb2301f93",
        name: "Palma Restoran Quba",
        city: 44,
        phone: "+994508975989",
        latitude: 41.3676148,
        longitude: 48.5352639,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10011410.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10183889.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10025517.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10112819.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:30.529Z",
        updatedAt: "2024-04-30T13:31:14.149Z",
      },
      {
        id: "dddbf5bc-eadc-4659-b3f3-fc9e03e8b371",
        name: "Tərəkəmə Restoranı",
        city: 34,
        phone: "+994513776777",
        latitude: 39.9304741,
        longitude: 48.00686830000001,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10789288.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10512772.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10356903.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10122368.jpeg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:28.715Z",
        updatedAt: "2024-05-06T05:07:47.001Z",
      },
      {
        id: "985005e2-c26b-4531-8fe3-c5b5fe1cf3c7",
        name: "Yaşıl Göl Restoranı",
        city: 4,
        phone: "+994504842424",
        latitude: 41.220368199999996,
        longitude: 45.4349638,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10135658.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10381655.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10498631.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10143056.jpeg",
        ],
        rate: 4,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:27.469Z",
        updatedAt: "2024-05-10T13:58:35.493Z",
      },
      {
        id: "53dd58fa-f28d-4773-bacf-420f654b8bb4",
        name: "Fontan Nabran Restoran",
        city: 27,
        phone: "+994505759235",
        latitude: 41.777536399999995,
        longitude: 48.6633065,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10611713.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10247028.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10221232.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10671914.jpg",
        ],
        rate: 0.5,
        rateCount: 2,
        status: true,
        createdAt: "2024-04-22T10:49:30.781Z",
        updatedAt: "2024-04-30T13:29:32.244Z",
      },
      {
        id: "a6f5e545-ab2c-4569-b0e4-e84cf196acb7",
        name: "Xidir Restoran",
        city: 46,
        phone: "+994504482323",
        latitude: 41.4106089,
        longitude: 48.401641,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10880447.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10361837.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10241919.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10478401.jpg",
        ],
        rate: 4,
        rateCount: 2,
        status: true,
        createdAt: "2024-04-22T10:49:32.425Z",
        updatedAt: "2024-05-06T03:32:39.684Z",
      },
      {
        id: "b6187215-2c37-47b7-ad8f-1706e2fb18b9",
        name: "Nohur Göl Restoran",
        city: 41,
        phone: "+994552659432",
        latitude: 40.9552534,
        longitude: 47.87823890000001,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10258313.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10428311.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10596977.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10808317.jpeg",
        ],
        rate: 4,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:29.440Z",
        updatedAt: "2024-04-25T12:23:43.083Z",
      },
      {
        id: "abb0d47f-ac53-4034-98fb-ecee94df9ece",
        name: "Xutorok Qusar",
        city: 46,
        phone: "+994505252151",
        latitude: 41.4038099,
        longitude: 48.3854341,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10252168.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10183088.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10576599.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10862090.jpg",
        ],
        rate: 4.285714285714286,
        rateCount: 7,
        status: true,
        createdAt: "2024-04-22T10:49:32.730Z",
        updatedAt: "2024-05-14T04:52:19.674Z",
      },
    ],
    otelsCards: [
      {
        id: "a0aa7d2f-bcf6-4849-95ce-8899c085b25e",
        name: "Xan Lənkəran oteli",
        city: 49,
        phone: "+994503905446",
        latitude: 38.779424690662836,
        longitude: 48.8250614,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10567650.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10848666.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10173210.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10011301.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:20.026Z",
        updatedAt: "2024-04-22T11:55:20.026Z",
      },
      {
        id: "48950a4d-cfe0-4c80-b34d-ff5ff46fa026",
        name: "Çalat parkı, Şahdağ",
        city: 46,
        phone: "+994123102020",
        latitude: 41.32026984896755,
        longitude: 48.134211915343386,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10704824.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10753711.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10824391.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10850483.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:19.502Z",
        updatedAt: "2024-04-22T11:55:19.502Z",
      },
      {
        id: "756e90fa-d71a-45f5-8c0e-4d97a9817f55",
        name: "Issam Hotel & Spa",
        city: 68,
        phone: "+994242448781",
        latitude: 41.204543570106495,
        longitude: 47.1749989460326,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10596389.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10251244.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10496999.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10099957.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:19.811Z",
        updatedAt: "2024-04-22T11:55:19.811Z",
      },
      {
        id: "d9490bc4-ff9c-4a3a-a819-56a932ce81cc",
        name: "Tufandağ dağ kurortu",
        city: 41,
        phone: "+994123101191",
        latitude: 41.03381465594725,
        longitude: 47.887361492502,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10359779.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10556229.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10672348.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10307270.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:20.597Z",
        updatedAt: "2024-04-22T11:55:20.597Z",
      },
      {
        id: "e9363c54-1b35-43cb-8f39-c0f9996b8156",
        name: "Viva Hotel Qəbələ",
        city: 41,
        phone: "+994503111051",
        latitude: 40.9992659637207,
        longitude: 47.8634157544908,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10861056.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10241381.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10584061.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10486965.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.522Z",
        updatedAt: "2024-04-22T11:55:21.522Z",
      },
      {
        id: "b5664cc2-c110-4870-bdde-b234480b8de5",
        name: "Arena Grand Hotel",
        city: 44,
        phone: "+994102607646",
        latitude: 41.36303939945925,
        longitude: 48.5654852539673,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10391903.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10169242.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10674655.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10086862.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.629Z",
        updatedAt: "2024-04-22T11:55:21.629Z",
      },
      {
        id: "275b2465-7a9c-4b5d-bed3-97cddbda196b",
        name: "Şahdağ Hotel & Spa",
        city: 46,
        phone: "+994123101110",
        latitude: 41.32005214483496,
        longitude: 48.1367733460301,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10482096.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10488129.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10362000.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10015095.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.821Z",
        updatedAt: "2024-04-22T11:55:21.821Z",
      },
      {
        id: "3c12a282-57b3-47fa-af22-24f3899a808d",
        name: "Quba Palace Hotel",
        city: 44,
        phone: "+994992550055",
        latitude: 41.36446686361403,
        longitude: 48.497945272117434,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10110489.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10178644.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10627977.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10241357.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.042Z",
        updatedAt: "2024-04-22T11:55:23.042Z",
      },
      {
        id: "074d183c-c6c3-4aa5-b3a8-a36451ff71e5",
        name: "Excelsior Hotel Şəmkir",
        city: 69,
        phone: "+994552085700",
        latitude: 40.83811193240524,
        longitude: 46.02514095767095,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10434880.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10434671.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10695877.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10526322.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.181Z",
        updatedAt: "2024-04-22T11:55:23.181Z",
      },
      {
        id: "5e1b08ea-7991-47ca-8427-4a0e4aff65cc",
        name: "Premium Hotel Gəncə",
        city: 20,
        phone: "+994222668002",
        latitude: 40.6921454412308,
        longitude: 46.36684613068844,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10058531.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10632021.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10572098.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10245525.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:22.908Z",
        updatedAt: "2024-04-30T07:49:49.155Z",
      },
      {
        id: "f92fb6c3-2962-4dcb-aa62-7e1aaf45f8dd",
        name: "VIS Palm Hotel Gəncə",
        city: 20,
        phone: "+994222557755",
        latitude: 40.696362381776154,
        longitude: 46.376788369311576,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10477309.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10653983.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10059513.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10293437.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.884Z",
        updatedAt: "2024-04-22T11:55:21.884Z",
      },
      {
        id: "0d449c2a-6f7e-4f51-9e26-dc15dbde644b",
        name: "ILK INN Hotel",
        city: 46,
        phone: "+994508770038",
        latitude: 41.4229154114882,
        longitude: 48.42545012327892,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10237578.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10497412.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10376992.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10585296.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:22.156Z",
        updatedAt: "2024-04-22T11:55:22.156Z",
      },
      {
        id: "b4bf1b3e-47bd-4867-9c72-7286c153db38",
        name: "Çinar Hotel & SPA Naftalan",
        city: 53,
        phone: "+994223521000",
        latitude: 40.50031887867114,
        longitude: 46.82033121534421,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10346398.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10161574.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10342637.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10437358.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:22.872Z",
        updatedAt: "2024-04-22T11:55:22.872Z",
      },
      {
        id: "8376fefc-ae77-431e-9527-64b241b1a029",
        name: "Ayan Palace Hotel",
        city: 74,
        phone: "+994502299009",
        latitude: 40.9892940041353,
        longitude: 45.6445344719021,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10427018.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10379501.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10388571.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10858711.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:22.945Z",
        updatedAt: "2024-04-22T11:55:22.945Z",
      },
      {
        id: "b31e1e18-12b0-4cb5-a70e-81565fb77e74",
        name: "Lənkəran Springs Sağlamlıq İstirahəti",
        city: 49,
        phone: "+994512359600",
        latitude: 38.7678686901812,
        longitude: 48.7605465539673,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10200214.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10017001.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10080458.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10245687.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.185Z",
        updatedAt: "2024-04-22T11:55:23.185Z",
      },
      {
        id: "fd61eb86-703e-46aa-87bf-ac108fae4c90",
        name: "Afra Hotel",
        city: 56,
        phone: "+994554448001",
        latitude: 41.066667883298706,
        longitude: 47.46921779630336,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10430575.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10438305.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10066831.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10593006.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.775Z",
        updatedAt: "2024-04-22T11:55:23.775Z",
      },
      {
        id: "48a4dda0-97a1-4acc-9ac8-d77d7d05d131",
        name: "El Resort Hotel Qax",
        city: 39,
        phone: "+994704455202",
        latitude: 41.43761866336678,
        longitude: 46.9782317883547,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10128237.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10631118.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10139416.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10796895.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:25.194Z",
        updatedAt: "2024-04-22T11:55:25.194Z",
      },
      {
        id: "995d3fa1-c50d-490c-a528-b0da7a23a519",
        name: "QALAALTI HOTEL & SPA",
        city: 63,
        phone: "+994125040909",
        latitude: 41.090036889923304,
        longitude: 48.93729562698716,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10736668.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10794163.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10742653.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10482155.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:25.391Z",
        updatedAt: "2024-04-22T11:55:25.391Z",
      },
      {
        id: "5eeff7a6-706a-4d9b-8c80-c7c401265d40",
        name: "River Inn Boutique Hotel",
        city: 27,
        phone: "+994552150193",
        latitude: 41.778411255144654,
        longitude: 48.67038445952448,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10812818.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10121697.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10538597.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10586331.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:25.816Z",
        updatedAt: "2024-04-22T11:55:25.816Z",
      },
      {
        id: "631b4ed9-336f-4439-a1c5-315e8908d2e9",
        name: "Old Town Şəki",
        city: 68,
        phone: "+994514666234",
        latitude: 41.20095427172368,
        longitude: 47.1868375306884,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10777404.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10881024.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10296713.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10456013.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:29.076Z",
        updatedAt: "2024-04-22T11:55:29.076Z",
      },
      {
        id: "a0628ef4-7628-4a7e-9996-9e09a9432b7a",
        name: "Vogue Hotel Nabran",
        city: 27,
        phone: "+994502633333",
        latitude: 41.70508623717405,
        longitude: 48.73793077672107,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10318253.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10221725.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10137920.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10383227.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:23.457Z",
        updatedAt: "2024-05-12T14:45:42.181Z",
      },
      {
        id: "3218c057-b83f-4b9a-afe0-ae57820ec387",
        name: "Göy Göl Resort",
        city: 23,
        phone: "+994512296477",
        latitude: 40.58753352169726,
        longitude: 46.31582895767095,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10391504.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10473005.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10453650.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10809232.jpg",
        ],
        rate: 1,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:34.104Z",
        updatedAt: "2024-04-30T13:35:48.392Z",
      },
      {
        id: "5fde5403-fe74-4eb2-a04b-2466fb3f815b",
        name: "Şamaxı Sarayı Şərdil",
        city: 67,
        phone: "+994125040909",
        latitude: 40.62603963703284,
        longitude: 48.46919538465661,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10844581.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10833099.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10406762.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10064297.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:25.177Z",
        updatedAt: "2024-05-12T19:35:57.827Z",
      },
    ],
    activitiesCards: [
      {
        id: "00e6917e-7d14-447a-9b7f-172718bed0a9",
        name: "Oxatma",
        city: 46,
        phone: "+994123101110",
        latitude: 41.31939138050239,
        longitude: 48.136623138682374,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/энвато19.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/Archery.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/thumb__536_237_0_0_auto.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/thumb__536_237_0_0_auto.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:55.533Z",
        updatedAt: "2024-04-22T12:24:55.533Z",
      },
      {
        id: "6c3c6774-b9db-40f0-be1c-af6c14a9ef0f",
        name: "Kvadrosikl turu",
        city: 41,
        phone: "+994502904333",
        latitude: 41.03357551118855,
        longitude: 47.886837984658015,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/jiSCQHJUVSW.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/2y1ge8ycf.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/JBgac.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/ZNXOSAC.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:54.969Z",
        updatedAt: "2024-04-22T12:24:54.969Z",
      },
      {
        id: "33c09705-f7d9-4f4c-be71-9b5158b6bc87",
        name: "Atıcılıq",
        city: 8,
        phone: "+994774095854",
        latitude: 40.40937583448257,
        longitude: 49.83803979643032,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/302673137_387471783533786_1956829846892196929_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/aHSDIUAgdi.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1465857454_b69729df-e498-45bf-89d4-55f25fce76011.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/a3.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:55.862Z",
        updatedAt: "2024-04-22T12:24:55.862Z",
      },
      {
        id: "819d2c43-28e0-4d92-a239-10f940bb39cb",
        name: "Şəki Xan Sarayı",
        city: 68,
        phone: "+",
        latitude: 41.2049585931624,
        longitude: 47.197843676709915,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/original.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/image139.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/Şəki_xan_sarayı.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/_1564090113.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:55.866Z",
        updatedAt: "2024-04-22T12:24:55.866Z",
      },
      {
        id: "c1ce2b1d-66cc-40d9-b5ab-40b588f46533",
        name: "Lahıc Tarix-Diyarşünaslıq Muzeyi",
        city: 35,
        phone: "+994202877579",
        latitude: 40.84609882135035,
        longitude: 48.38088086136793,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/Музей_Лагич.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/images.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/az.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/6200.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.072Z",
        updatedAt: "2024-04-22T12:24:56.072Z",
      },
      {
        id: "5b2da2e6-9cb1-4660-a450-978c1749283e",
        name: "Atıcılıq",
        city: 46,
        phone: "+994123101110",
        latitude: 41.31939138050238,
        longitude: 48.136623138682374,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/7c8c6184-9840-4c98-bd7b-0089806c5995.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1-1-1042x460.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1-1-4.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/DSC_3262-1042x460.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.651Z",
        updatedAt: "2024-04-22T12:24:56.651Z",
      },
      {
        id: "7ab4dc0e-6eb1-41e7-aa99-e60cc459b5eb",
        name: "Kartinq",
        city: 8,
        phone: "+994552773303",
        latitude: 40.420332422986874,
        longitude: 49.8545374920519,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/273584034_4853590738027648_1821989458576670365_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/305571021_442724404545135_8062463216197273059_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/274757158_4909460099107378_6774754324889914171_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/272258674_4791170404269682_3378642945395299124_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.675Z",
        updatedAt: "2024-04-22T12:24:56.675Z",
      },
      {
        id: "fb9db632-9012-4444-81b2-b12c3d7beae2",
        name: "Lerik Uzunömürlülər Muzeyi",
        city: 48,
        phone: "+",
        latitude: 38.7771375854217,
        longitude: 48.41951712329,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/119033421_635927330399635_8702102511087232637_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/118953534_635927337066301_9195035406779080653_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/119053681_635927327066302_7626096416254120685_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/119056895_635927333732968_6840896585176596118_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.858Z",
        updatedAt: "2024-04-22T12:24:56.858Z",
      },
      {
        id: "96fa82d9-1143-4827-af8f-effa33a74988",
        name: "Qolf",
        city: 44,
        phone: "+994992550055",
        latitude: 41.33335571677116,
        longitude: 48.5126209,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347249959_228925656432162_61673056183458145_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347261578_1675367056246469_6987559658485070502_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347437908_237956288925549_5580597315769921114_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347226756_983007866478397_8384600699586982265_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:57.125Z",
        updatedAt: "2024-04-22T12:24:57.125Z",
      },
      {
        id: "1f8e88e5-a638-4e91-9bf7-f6eede987d6b",
        name: "Zipline",
        city: 68,
        phone: "+994518788574",
        latitude: 41.19762058241731,
        longitude: 47.15711336751136,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/69501941_2416459841958633_8338188077537165312_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/69400593_2411827222421895_4395387788908822528_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/67925153_2411298762474741_8196892982167732224_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/68633829_2410224625915488_2510518150991183872_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:57.560Z",
        updatedAt: "2024-04-22T12:24:57.560Z",
      },
      {
        id: "ff1b47d1-bddf-46c6-8a39-2152d87c478e",
        name: "Xizək",
        city: 41,
        phone: "+994502904333",
        latitude: 41.03357551118856,
        longitude: 47.886837984658015,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/430886358_454752806879362_520785276005016516_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/428358310_441235818231061_7527026784287096782_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/432026830_457869676567675_5764667216684864732_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/432016131_457869563234353_4186749515429917977_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:58.620Z",
        updatedAt: "2024-04-22T12:24:58.620Z",
      },
      {
        id: "d285fd8e-9976-4abe-8161-ea4dafa94c1c",
        name: "Airsoft",
        city: 8,
        phone: "+994552151189",
        latitude: 40.41979133767852,
        longitude: 49.891064677264126,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/2.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/3.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/4.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:58.824Z",
        updatedAt: "2024-04-22T12:24:58.824Z",
      },
      {
        id: "4cd1b294-bd0a-4cee-a802-9392ea9777a8",
        name: "Yamaç Paraşütü",
        city: 63,
        phone: "+994508709676",
        latitude: 40.931185837978,
        longitude: 49.23547115396983,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/315441980_516245133851566_3244794267270162408_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/348568435_6613754561969237_5023243877282647426_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/306080169_463588869117193_8414068152784025366_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/315442982_516245140518232_6253017973092592291_n.jpg",
        ],
        rate: 3.5,
        rateCount: 2,
        status: true,
        createdAt: "2024-04-22T12:25:00.175Z",
        updatedAt: "2024-05-03T11:59:29.866Z",
      },
    ],
    cities: [
      "Ağcabədi",
      "Ağdam",
      "Ağdaş",
      "Ağdərə",
      "Ağstafa",
      "Ağsu",
      "Astara",
      "Babək",
      "Bakı",
      "Balakən",
      "Beyləqan",
      "Bərdə",
      "Biləsuvar",
      "Cəbrayıl",
      "Cəlilabad",
      "Culfa",
      "Daşkəsən",
      "Dəliməmmədli",
      "Füzuli",
      "Gədəbəy",
      "Gəncə",
      "Goranboy",
      "Göyçay",
      "Göygöl",
      "Göytəpə",
      "Hacıqabul",
      "Horadiz",
      "Xaçmaz",
      "Xankəndi",
      "Xocalı",
      "Xocavənd",
      "Xızı",
      "Xırdalan",
      "Xudat",
      "İmişli",
      "İsmayıllı",
      "Kəlbəcər",
      "Kürdəmir",
      "Kəngərli",
      "Qax",
      "Qazax",
      "Qəbələ",
      "Qobustan",
      "Qovlar",
      "Quba",
      "Qubadlı",
      "Qusar",
      "Laçın",
      "Lerik",
      "Lənkəran",
      "Liman",
      "Masallı",
      "Mingəçevir",
      "Naftalan",
      "Naxçıvan",
      "Neftçala",
      "Oğuz",
      "Ordubad",
      "Saatlı",
      "Sabirabad",
      "Salyan",
      "Samux",
      "Sədərək",
      "Siyəzən",
      "Sumqayıt",
      "Şabran",
      "Şahbuz",
      "Şamaxı",
      "Şəki",
      "Şəmkir",
      "Şərur",
      "Şirvan",
      "Şuşa",
      "Tərtər",
      "Tovuz",
      "Ucar",
      "Yardımlı",
      "Yevlax",
      "Zaqatala",
      "Zəngilan",
      "Zərdab",
      "Maştağa",
      "Qobu",
      "Buzovna",
      "Qala",
      "Balaxanı",
      "Zığ",
      "Binə",
      "Zirə",
      "Şüvəlan",
      "Türkan",
      "Kürdəxanı",
      "Zabrat",
      "Keşlə",
      "Əmircan",
      "Bilgəh",
      "Corat",
      "Bülbülə",
      "Bibiheybət",
      "Biləcəri",
      "Masazır",
      "Digah",
      "Yeni Ramana",
      "Ramana",
      "Pirşağı",
      "Pirallahı",
      "Mərdəkan",
      "Şağan",
      "Dübəndi",
      "Hökməli",
      "Hövsan",
      "Sahil q./ Primorsk",
      "Ələt",
      "Badamdar",
      "Lökbatan",
      "Görədil",
      "Fatmayı",
      "Nardaran",
      "Novxanı",
      "Saray",
      "Yeni Suraxanı",
      "Mehdiabad",
      "Sulutəpə",
      "Qaraçuxur",
      "Yeni Günəşli",
      "Bakıxanov",
      "Əhmədli Metrosu",
      "Xalqlar Metrosu",
      "Neftçilər Metrosu",
      "Qarayev Metrosu",
      "Koroğlu Metrosu",
      "Ulduz Metrosu",
      "Bakmil Metrosu",
      "Nərimanov Metrosu",
      "Gənclik Metrosu",
      "28 May Metrosu",
      "Xətai Metrosu",
      "Sahil Metrosu",
      "İçərişəhər Metrosu",
      "Nizami Metrosu",
      "Elmlər Metrosu",
      "İnşaatçılar Metrosu",
      "20 Yanvar Metrosu",
      "Əcəmi Metrosu",
      "Nəsimi Metrosu",
      "Azadlıq Metrosu",
      "Dərnəgül Metrosu",
      "8 Noyabr Metrosu",
      "Avtovağzal Metrosu",
      "Xocəsən Metrosu",
    ],
    comment: "rəy",
    road: "Yol",
    fromCar: "Təhvil alma yeri",
  },
  EN: {
    sloqan: "Go the economical way!",
    from: "From",
    to: "To",
    language: "Language",
    download: "Download App!",
    infoPart1: "We are at your service with low prices",
    infoPart2: "You can trust us",
    infoPart3: "Scroll, find, click and go!",
    infoDesc1:
      "No matter where you're going, find the perfect trip from our wide range of destinations and itineraries at low prices.",
    infoDesc2:
      "We take the time to get to know each of our members and partners. We check reviews, profiles and IDs so you know who you're traveling with and book your trip conveniently on our secure platform.",
    infoDesc3:
      "Booking travel has never been easier! Thanks to our simple application, equipped with excellent technology, you can book a trip near you in minutes.",
    sloqan2: "Go together, reduce the cost!",
    appOnPlatforms: "The Gezz application is now available on IOS and Android.",
    address: "Address:",
    addressDetails: "Baku, Islam Abishov, 104",
    phone: "Contact number:",
    phoneDetails: "+994 55 254 40 40",
    email: "Email:",
    emailDetails: "info@gezz.az",
    copyright1: "© 2024 All Copyrights Reserved To Gezz",
    copyright2: "Privacy Policy ",
    copyright3: "Terms & Conditions",
    faq: "FAQ",
    howToUseIt: "How to use?",
    whatIs: "What is Gezz?",
    aboutUs: "Haqqımızda",
    blog: "About us",
    actions: "Actions",
    restourants: "Restaurants",
    otels: "Hotels",
    travels: "Departures",
    usersThrouts: "Thoughts from our users",
    feedbacks: [
      {
        id: 1,
        stars: 5,
        date: "17 Jan 2024",
        desc: "Great idea and program. If it becomes famous, it will be a great convenience for people. Both passenger and driver.",
        name: "Simral",
        title: "item #1",
      },
      {
        id: 2,
        stars: 5,
        date: "7 Dec 2023",
        desc: "Everyone should use it, both the driver and the road user.",
        name: "Javid Mirzeyev",
        title: "item #1",
      },
      {
        id: 3,
        stars: 5,
        date: "14 Jan 2023",
        desc: "When you go somewhere very super, you give 5 manats instead of 20 manats.",
        name: "Izzet Guleliyev",
        title: "item #1",
      },
      {
        id: 4,
        stars: 5,
        date: "17 Jan 2024",
        desc: "Great idea and program. If it becomes famous, it will be a great convenience for people. Both passenger and driver.",
        name: "Simral",
        title: "item #1",
      },
      {
        id: 5,
        stars: 5,
        date: "7 Dec 2023",
        desc: "Everyone should use it, both the driver and the road user.",
        name: "Javid Mirzeyev",
        title: "item #1",
      },
      {
        id: 6,
        stars: 5,
        date: "14 Jan 2023",
        desc: "When you go somewhere very super, you give 5 manats instead of 20 manats.",
        name: "Izzet Guleliyev",
        title: "item #1",
      },
    ],
    yourSecurityTile: "Your safety is very important to us",
    yourSecurityDesc:
      "At Gezz, we work hard to make our platform as safe as possible. But we want you to know exactly how to avoid scams and how to report them when they happen. Follow our tips to help keep you safe.",
    yourSecurityMore: "Learn more",
    aboutTile: "About Gezz",
    aboutDesc: [
      "Gezz is a travel platform.",
      "Here you can find users going to different regions, travel together, and share travel costs.",
      "You can discover Restaurants/Hotels/Entertainment in various cities through Gezz.",
      "If you need a car for travel, you can browse rental cars in the Gezz app."
    ],
    aboutMore: "Download the app and enjoy your trip!",
    carPoolingTitle: "Carpooling means moving together 🌱",
    carPoolingDesc:
      "In addition to your personal benefits, you take measures to make mobility simpler and smarter. Gezz has been preventing CO2 emissions since its inception:",
    carPoolingDesc2:
      "Share your journey with Gezz and reduce your environmental impact",
    carPoolingButton: "Download Gezz",
    carPoolingCards: [
      {
        icon: "🌲",
        desc: "Every day means planting a tree",
      },
      {
        icon: "💧",
        desc: "Means unconsumed drinking water",
      },
      {
        icon: "🕝",
        desc: "Equivalent to the consumption of 1 Azerbaijani for days",
      },
    ],
    howItsWorkTitle: "How does it work?",
    howItsWorkDesc:
      "More than a ride sharing platform, Gezz is an innovative travel app. It allows you to share your trips, organize them at the last minute or in advance. In addition, it helps you find alternatives such as hotels, restaurants, and entertainment in your travels",
    howItsWorkCards: [
      {
        number: "01",
        title: "Download the Gezz app",
        description:
          "Gezz takes care of finding you travel deals that fit your habits, so you don't have to do anything! Karos understands you and offers you the most suitable carpoolers for your habits.",
      },
      {
        number: "02",
        title: "Choose the offer that suits you best",
        description:
          "You can send a request to the ads of drivers as a passenger or to passengers as a driver.",
      },
      {
        number: "03",
        title: "Confirm in 2 clicks!",
        description:
          "Choose the trip that suits you and book it for free. You can also take advantage of many other functions, such as in-app messaging, price negotiation, and priority on trips.",
      },
    ],
    everyDayGrow: "A community that grows every day",
    usersCountName: "users",
    advantagesTitle: "Discover your strengths",
    advantagesDesc:
      "With Gezz, we make it possible to save time and money, meet new people, and protect the environment!",
    advantagesCards: [
      {
        person: "Traveller",
        desc: "Go with the comfort of a car for the price of a bus 🚌",
        price: "0",
        currency: "AZN",
        priceDesc: "with prices starting from",
        more: "More benefits >",
        image: "https://gezz.az/assets/firstImage.png",
      },
      {
        person: "Driver",
        desc: "Save your gas money 💸",
        price: "+ 200",
        currency: "AZN",
        priceDesc: "up to extra earnings",
        more: "More benefits >",
        image: "https://gezz.az/assets/secondImage.png",
      },
    ],
    DivisPartTitle1: "Walk the road together",
    DivisPartTitle2: "and ",
    DivisPartTitle3: "Save money",
    DivisPartDesc: "The No. 1 app for convenient, affordable and fun commuting",
    DivisPartButton: "Download the app",
    searchTypes: [
      {
        id: 0,
        name: "Trips",
        image: [
          "https://gezz.az/assets/riderWhite.svg",
          "https://gezz.az/assets/riderBlack.svg",
        ],
      },
      {
        id: 1,
        name: "Cars",
        image: [
          "https://gezz.az/assets/carWhite.png",
          "https://gezz.az/assets/carBlack.png",
        ],
      },
      {
        id: 2,
        name: "Restaurants",
        image: [
          "https://gezz.az/assets/restaurantWhite.svg",
          "https://gezz.az/assets/restaurantBlack.svg",
        ],
      },
      {
        id: 3,
        name: "Hotels",
        image: [
          "https://gezz.az/assets/otelWhite.svg",
          "https://gezz.az/assets/otelBlack.svg",
        ],
      },
      {
        id: 4,
        name: "Activities",
        image: [
          "https://gezz.az/assets/activityWhite.svg",
          "https://gezz.az/assets/activityBlack.svg",
        ],
      },
    ],
    search: "Search",
    searchNames: [
      "Search for a trip or city",
      "Search for a car",
      "Search for a restaurant or city",
      "Search for a hotel or city",
      "Search for a activity or city",
    ],
    searchNamesNotFound: [
      "Unfortunately, no trip matching your search was found.",
      "Unfortunately, no car matching your search was found.",
      "Unfortunately, no restaurant matching your search was found.",
      "Unfortunately, no hotel matching your search was found.",
      "Unfortunately, no activity matching your search was found.",
    ],
    restourantsCards: [
      {
        id: "90fa6dae-ce55-4e0d-9ab0-2f437aef5e88",
        name: "Elite Restaurant",
        city: 69,
        phone: "+994514305758",
        latitude: 40.8379832,
        longitude: 46.034589,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10839982.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10583026.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10547512.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10818466.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:22.455Z",
        updatedAt: "2024-04-22T10:49:22.455Z",
      },
      {
        id: "db211c28-331a-4e8b-97a1-0754b7409f76",
        name: "Shirkhan Restaurant",
        city: 19,
        phone: "+994506838552",
        latitude: 40.6693287,
        longitude: 45.823086599999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10732543.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10296600.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10702488.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10174414.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:24.826Z",
        updatedAt: "2024-04-22T10:49:24.826Z",
      },
      {
        id: "083b4066-5970-46a2-9a04-12c450f89b89",
        name: "Narin Castle",
        city: 68,
        phone: "+994505655708",
        latitude: 40.9243449,
        longitude: 46.160612,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10480283.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10613888.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10858569.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10043608.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:24.978Z",
        updatedAt: "2024-04-22T10:49:24.978Z",
      },
      {
        id: "25aa4399-e9bf-4bae-81eb-d3eb13a9ba05",
        name: "Qala Restaurant",
        city: 69,
        phone: "+994503105711",
        latitude: 40.794785399999995,
        longitude: 46.079800399999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10291408.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10896051.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10741049.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10657594.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:26.213Z",
        updatedAt: "2024-04-22T10:49:26.213Z",
      },
      {
        id: "6c0da42d-9ae7-45f0-8685-9b22bcb953e8",
        name: "Artillery Restaurant",
        city: 35,
        phone: "+994552898999",
        latitude: 40.8811574,
        longitude: 48.069077899999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10747024.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10760497.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10557546.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10350139.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:26.635Z",
        updatedAt: "2024-04-22T10:49:26.635Z",
      },
      {
        id: "0fcf41c7-4020-4d2e-8946-30ad28eafa35",
        name: "Melham restaurant",
        city: 67,
        phone: "+994508806060",
        latitude: 40.6888971,
        longitude: 48.6377373,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10720851.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10285355.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10342549.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10702998.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:26.914Z",
        updatedAt: "2024-04-22T10:49:26.914Z",
      },
      {
        id: "fca43cc3-766f-4a3d-aa97-63358930691e",
        name: "Green Restaurant",
        city: 0,
        phone: "+994504605759",
        latitude: 40.0387139,
        longitude: 47.4545495,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10226491.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10607073.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10678683.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10875018.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:27.540Z",
        updatedAt: "2024-04-22T10:49:27.540Z",
      },
      {
        id: "a83a75ad-4ad4-4f91-95e6-fd73d811a71f",
        name: "Mountain Breeze",
        city: 44,
        phone: "+994519993999",
        latitude: 41.2324722,
        longitude: 48.3039133,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10428679.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10221857.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10028030.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10215119.jpeg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:28.225Z",
        updatedAt: "2024-04-22T10:49:28.225Z",
      },
      {
        id: "43d4c8ca-f3e7-423d-a31a-5559a722e610",
        name: "Istanbul Restaurant Kazakh",
        city: 40,
        phone: "+994708005959",
        latitude: 41.0900585,
        longitude: 45.358627999999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10040458.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10378458.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10134297.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10315137.jpeg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:28.373Z",
        updatedAt: "2024-04-22T10:49:28.373Z",
      },
      {
        id: "66e51461-98b9-4eea-bc11-f751d900f19f",
        name: "Çanlibel Restaurant",
        city: 27,
        phone: "+994517172222",
        latitude: 41.4855261,
        longitude: 48.7762076,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10422249.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10493813.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10336291.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10191671.jpeg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:29.271Z",
        updatedAt: "2024-04-22T10:49:29.271Z",
      },
      {
        id: "437cf249-d8c8-4d96-b408-4ba92350b0e8",
        name: "Abqora Restaurant",
        city: 67,
        phone: "+994502950212",
        latitude: 40.6450672,
        longitude: 48.5842208,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10424984.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10277150.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10430604.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10257791.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:30.329Z",
        updatedAt: "2024-04-22T10:49:30.329Z",
      },
      {
        id: "1a0fa0aa-20c3-4a74-a51f-279bb2301f93",
        name: "Palma Restaurant Guba",
        city: 44,
        phone: "+994508975989",
        latitude: 41.3676148,
        longitude: 48.5352639,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10011410.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10183889.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10025517.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10112819.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:30.529Z",
        updatedAt: "2024-04-30T13:31:14.149Z",
      },
      {
        id: "dddbf5bc-eadc-4659-b3f3-fc9e03e8b371",
        name: "Tarakama Restaurant",
        city: 34,
        phone: "+994513776777",
        latitude: 39.9304741,
        longitude: 48.00686830000001,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10789288.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10512772.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10356903.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10122368.jpeg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:28.715Z",
        updatedAt: "2024-05-06T05:07:47.001Z",
      },
      {
        id: "985005e2-c26b-4531-8fe3-c5b5fe1cf3c7",
        name: "Green Lake Restaurant",
        city: 4,
        phone: "+994504842424",
        latitude: 41.220368199999996,
        longitude: 45.4349638,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10135658.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10381655.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10498631.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10143056.jpeg",
        ],
        rate: 4,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:27.469Z",
        updatedAt: "2024-05-10T13:58:35.493Z",
      },
      {
        id: "53dd58fa-f28d-4773-bacf-420f654b8bb4",
        name: "Fontan Nabran Restaurant",
        city: 27,
        phone: "+994505759235",
        latitude: 41.777536399999995,
        longitude: 48.6633065,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10611713.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10247028.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10221232.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10671914.jpg",
        ],
        rate: 0.5,
        rateCount: 2,
        status: true,
        createdAt: "2024-04-22T10:49:30.781Z",
        updatedAt: "2024-04-30T13:29:32.244Z",
      },
      {
        id: "a6f5e545-ab2c-4569-b0e4-e84cf196acb7",
        name: "Khidir Restaurant",
        city: 46,
        phone: "+994504482323",
        latitude: 41.4106089,
        longitude: 48.401641,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10880447.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10361837.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10241919.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10478401.jpg",
        ],
        rate: 4,
        rateCount: 2,
        status: true,
        createdAt: "2024-04-22T10:49:32.425Z",
        updatedAt: "2024-05-06T03:32:39.684Z",
      },
      {
        id: "b6187215-2c37-47b7-ad8f-1706e2fb18b9",
        name: "Nohur Lake Restaurant",
        city: 41,
        phone: "+994552659432",
        latitude: 40.9552534,
        longitude: 47.87823890000001,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10258313.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10428311.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10596977.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10808317.jpeg",
        ],
        rate: 4,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:29.440Z",
        updatedAt: "2024-04-25T12:23:43.083Z",
      },
      {
        id: "abb0d47f-ac53-4034-98fb-ecee94df9ece",
        name: "Khutorok Gusar",
        city: 46,
        phone: "+994505252151",
        latitude: 41.4038099,
        longitude: 48.3854341,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10252168.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10183088.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10576599.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10862090.jpg",
        ],
        rate: 4.285714285714286,
        rateCount: 7,
        status: true,
        createdAt: "2024-04-22T10:49:32.730Z",
        updatedAt: "2024-05-14T04:52:19.674Z",
      },
    ],
    otelsCards: [
      {
        id: "a0aa7d2f-bcf6-4849-95ce-8899c085b25e",
        name: "Khan Lankaran Hotel",
        city: 49,
        phone: "+994503905446",
        latitude: 38.779424690662836,
        longitude: 48.8250614,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10567650.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10848666.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10173210.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10011301.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:20.026Z",
        updatedAt: "2024-04-22T11:55:20.026Z",
      },
      {
        id: "48950a4d-cfe0-4c80-b34d-ff5ff46fa026",
        name: "Park Chalet, Shahdag",
        city: 46,
        phone: "+994123102020",
        latitude: 41.32026984896755,
        longitude: 48.134211915343386,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10704824.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10753711.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10824391.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10850483.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:19.502Z",
        updatedAt: "2024-04-22T11:55:19.502Z",
      },
      {
        id: "756e90fa-d71a-45f5-8c0e-4d97a9817f55",
        name: "Issam Hotel & Spa",
        city: 68,
        phone: "+994242448781",
        latitude: 41.204543570106495,
        longitude: 47.1749989460326,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10596389.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10251244.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10496999.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10099957.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:19.811Z",
        updatedAt: "2024-04-22T11:55:19.811Z",
      },
      {
        id: "d9490bc4-ff9c-4a3a-a819-56a932ce81cc",
        name: "Tufandag Mountain Resort",
        city: 41,
        phone: "+994123101191",
        latitude: 41.03381465594725,
        longitude: 47.887361492502,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10359779.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10556229.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10672348.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10307270.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:20.597Z",
        updatedAt: "2024-04-22T11:55:20.597Z",
      },
      {
        id: "e9363c54-1b35-43cb-8f39-c0f9996b8156",
        name: "Viva Hotel Gabala",
        city: 41,
        phone: "+994503111051",
        latitude: 40.9992659637207,
        longitude: 47.8634157544908,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10861056.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10241381.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10584061.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10486965.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.522Z",
        updatedAt: "2024-04-22T11:55:21.522Z",
      },
      {
        id: "b5664cc2-c110-4870-bdde-b234480b8de5",
        name: "Arena Grand Hotel",
        city: 44,
        phone: "+994102607646",
        latitude: 41.36303939945925,
        longitude: 48.5654852539673,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10391903.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10169242.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10674655.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10086862.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.629Z",
        updatedAt: "2024-04-22T11:55:21.629Z",
      },
      {
        id: "275b2465-7a9c-4b5d-bed3-97cddbda196b",
        name: "Shahdag Hotel & Spa",
        city: 46,
        phone: "+994123101110",
        latitude: 41.32005214483496,
        longitude: 48.1367733460301,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10482096.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10488129.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10362000.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10015095.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.821Z",
        updatedAt: "2024-04-22T11:55:21.821Z",
      },
      {
        id: "3c12a282-57b3-47fa-af22-24f3899a808d",
        name: "Quba Palace Hotel",
        city: 44,
        phone: "+994992550055",
        latitude: 41.36446686361403,
        longitude: 48.497945272117434,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10110489.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10178644.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10627977.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10241357.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.042Z",
        updatedAt: "2024-04-22T11:55:23.042Z",
      },
      {
        id: "074d183c-c6c3-4aa5-b3a8-a36451ff71e5",
        name: "Excelsior Hotel Shamkir",
        city: 69,
        phone: "+994552085700",
        latitude: 40.83811193240524,
        longitude: 46.02514095767095,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10434880.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10434671.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10695877.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10526322.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.181Z",
        updatedAt: "2024-04-22T11:55:23.181Z",
      },
      {
        id: "5e1b08ea-7991-47ca-8427-4a0e4aff65cc",
        name: "Premium Hotel Ganja",
        city: 20,
        phone: "+994222668002",
        latitude: 40.6921454412308,
        longitude: 46.36684613068844,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10058531.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10632021.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10572098.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10245525.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:22.908Z",
        updatedAt: "2024-04-30T07:49:49.155Z",
      },
      {
        id: "f92fb6c3-2962-4dcb-aa62-7e1aaf45f8dd",
        name: "VIS Palm Hotel Ganja",
        city: 20,
        phone: "+994222557755",
        latitude: 40.696362381776154,
        longitude: 46.376788369311576,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10477309.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10653983.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10059513.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10293437.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.884Z",
        updatedAt: "2024-04-22T11:55:21.884Z",
      },
      {
        id: "0d449c2a-6f7e-4f51-9e26-dc15dbde644b",
        name: "ILK INN Hotel",
        city: 46,
        phone: "+994508770038",
        latitude: 41.4229154114882,
        longitude: 48.42545012327892,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10237578.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10497412.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10376992.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10585296.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:22.156Z",
        updatedAt: "2024-04-22T11:55:22.156Z",
      },
      {
        id: "b4bf1b3e-47bd-4867-9c72-7286c153db38",
        name: "Chinar Hotel & SPA Naftalan",
        city: 53,
        phone: "+994223521000",
        latitude: 40.50031887867114,
        longitude: 46.82033121534421,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10346398.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10161574.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10342637.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10437358.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:22.872Z",
        updatedAt: "2024-04-22T11:55:22.872Z",
      },
      {
        id: "8376fefc-ae77-431e-9527-64b241b1a029",
        name: "Ayan Palace Hotel",
        city: 74,
        phone: "+994502299009",
        latitude: 40.9892940041353,
        longitude: 45.6445344719021,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10427018.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10379501.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10388571.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10858711.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:22.945Z",
        updatedAt: "2024-04-22T11:55:22.945Z",
      },
      {
        id: "b31e1e18-12b0-4cb5-a70e-81565fb77e74",
        name: "Lankaran Springs Wellness Resort",
        city: 49,
        phone: "+994512359600",
        latitude: 38.7678686901812,
        longitude: 48.7605465539673,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10200214.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10017001.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10080458.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10245687.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.185Z",
        updatedAt: "2024-04-22T11:55:23.185Z",
      },
      {
        id: "fd61eb86-703e-46aa-87bf-ac108fae4c90",
        name: "Afra Hotel",
        city: 56,
        phone: "+994554448001",
        latitude: 41.066667883298706,
        longitude: 47.46921779630336,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10430575.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10438305.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10066831.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10593006.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.775Z",
        updatedAt: "2024-04-22T11:55:23.775Z",
      },
      {
        id: "48a4dda0-97a1-4acc-9ac8-d77d7d05d131",
        name: "El Resort Hotel Qakh",
        city: 39,
        phone: "+994704455202",
        latitude: 41.43761866336678,
        longitude: 46.9782317883547,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10128237.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10631118.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10139416.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10796895.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:25.194Z",
        updatedAt: "2024-04-22T11:55:25.194Z",
      },
      {
        id: "995d3fa1-c50d-490c-a528-b0da7a23a519",
        name: "QALAALTI HOTEL & SPA",
        city: 63,
        phone: "+994125040909",
        latitude: 41.090036889923304,
        longitude: 48.93729562698716,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10736668.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10794163.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10742653.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10482155.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:25.391Z",
        updatedAt: "2024-04-22T11:55:25.391Z",
      },
      {
        id: "5eeff7a6-706a-4d9b-8c80-c7c401265d40",
        name: "River Inn Boutique Hotel",
        city: 27,
        phone: "+994552150193",
        latitude: 41.778411255144654,
        longitude: 48.67038445952448,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10812818.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10121697.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10538597.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10586331.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:25.816Z",
        updatedAt: "2024-04-22T11:55:25.816Z",
      },
      {
        id: "631b4ed9-336f-4439-a1c5-315e8908d2e9",
        name: "Old Town Sheki",
        city: 68,
        phone: "+994514666234",
        latitude: 41.20095427172368,
        longitude: 47.1868375306884,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10777404.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10881024.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10296713.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10456013.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:29.076Z",
        updatedAt: "2024-04-22T11:55:29.076Z",
      },
      {
        id: "a0628ef4-7628-4a7e-9996-9e09a9432b7a",
        name: "Vogue Hotel Nabran",
        city: 27,
        phone: "+994502633333",
        latitude: 41.70508623717405,
        longitude: 48.73793077672107,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10318253.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10221725.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10137920.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10383227.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:23.457Z",
        updatedAt: "2024-05-12T14:45:42.181Z",
      },
      {
        id: "3218c057-b83f-4b9a-afe0-ae57820ec387",
        name: "Goy Gol Lake Resort",
        city: 23,
        phone: "+994512296477",
        latitude: 40.58753352169726,
        longitude: 46.31582895767095,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10391504.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10473005.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10453650.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10809232.jpg",
        ],
        rate: 1,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:34.104Z",
        updatedAt: "2024-04-30T13:35:48.392Z",
      },
      {
        id: "5fde5403-fe74-4eb2-a04b-2466fb3f815b",
        name: "Shamakhi Palace Sharadil",
        city: 67,
        phone: "+994125040909",
        latitude: 40.62603963703284,
        longitude: 48.46919538465661,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10844581.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10833099.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10406762.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10064297.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:25.177Z",
        updatedAt: "2024-05-12T19:35:57.827Z",
      },
    ],
    activitiesCards: [
      {
        id: "00e6917e-7d14-447a-9b7f-172718bed0a9",
        name: "Archery",
        city: 46,
        phone: "+994123101110",
        latitude: 41.31939138050239,
        longitude: 48.136623138682374,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/энвато19.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/Archery.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/thumb__536_237_0_0_auto.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/thumb__536_237_0_0_auto.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:55.533Z",
        updatedAt: "2024-04-22T12:24:55.533Z",
      },
      {
        id: "6c3c6774-b9db-40f0-be1c-af6c14a9ef0f",
        name: "Quadrocycle tour",
        city: 41,
        phone: "+994502904333",
        latitude: 41.03357551118855,
        longitude: 47.886837984658015,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/jiSCQHJUVSW.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/2y1ge8ycf.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/JBgac.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/ZNXOSAC.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:54.969Z",
        updatedAt: "2024-04-22T12:24:54.969Z",
      },
      {
        id: "33c09705-f7d9-4f4c-be71-9b5158b6bc87",
        name: "Shooting",
        city: 8,
        phone: "+994774095854",
        latitude: 40.40937583448257,
        longitude: 49.83803979643032,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/302673137_387471783533786_1956829846892196929_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/aHSDIUAgdi.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1465857454_b69729df-e498-45bf-89d4-55f25fce76011.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/a3.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:55.862Z",
        updatedAt: "2024-04-22T12:24:55.862Z",
      },
      {
        id: "819d2c43-28e0-4d92-a239-10f940bb39cb",
        name: "Shaki Khan palace",
        city: 68,
        phone: "+",
        latitude: 41.2049585931624,
        longitude: 47.197843676709915,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/original.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/image139.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/Şəki_xan_sarayı.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/_1564090113.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:55.866Z",
        updatedAt: "2024-04-22T12:24:55.866Z",
      },
      {
        id: "c1ce2b1d-66cc-40d9-b5ab-40b588f46533",
        name: "Lahic Museum of History and Geography",
        city: 35,
        phone: "+994202877579",
        latitude: 40.84609882135035,
        longitude: 48.38088086136793,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/Музей_Лагич.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/images.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/az.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/6200.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.072Z",
        updatedAt: "2024-04-22T12:24:56.072Z",
      },
      {
        id: "5b2da2e6-9cb1-4660-a450-978c1749283e",
        name: "Shooting",
        city: 46,
        phone: "+994123101110",
        latitude: 41.31939138050238,
        longitude: 48.136623138682374,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/7c8c6184-9840-4c98-bd7b-0089806c5995.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1-1-1042x460.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1-1-4.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/DSC_3262-1042x460.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.651Z",
        updatedAt: "2024-04-22T12:24:56.651Z",
      },
      {
        id: "7ab4dc0e-6eb1-41e7-aa99-e60cc459b5eb",
        name: "Karting",
        city: 8,
        phone: "+994552773303",
        latitude: 40.420332422986874,
        longitude: 49.8545374920519,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/273584034_4853590738027648_1821989458576670365_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/305571021_442724404545135_8062463216197273059_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/274757158_4909460099107378_6774754324889914171_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/272258674_4791170404269682_3378642945395299124_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.675Z",
        updatedAt: "2024-04-22T12:24:56.675Z",
      },
      {
        id: "fb9db632-9012-4444-81b2-b12c3d7beae2",
        name: "Lerik Longevity Museum",
        city: 48,
        phone: "+",
        latitude: 38.7771375854217,
        longitude: 48.41951712329,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/119033421_635927330399635_8702102511087232637_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/118953534_635927337066301_9195035406779080653_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/119053681_635927327066302_7626096416254120685_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/119056895_635927333732968_6840896585176596118_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.858Z",
        updatedAt: "2024-04-22T12:24:56.858Z",
      },
      {
        id: "96fa82d9-1143-4827-af8f-effa33a74988",
        name: "Golf",
        city: 44,
        phone: "+994992550055",
        latitude: 41.33335571677116,
        longitude: 48.5126209,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347249959_228925656432162_61673056183458145_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347261578_1675367056246469_6987559658485070502_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347437908_237956288925549_5580597315769921114_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347226756_983007866478397_8384600699586982265_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:57.125Z",
        updatedAt: "2024-04-22T12:24:57.125Z",
      },
      {
        id: "1f8e88e5-a638-4e91-9bf7-f6eede987d6b",
        name: "Zipline",
        city: 68,
        phone: "+994518788574",
        latitude: 41.19762058241731,
        longitude: 47.15711336751136,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/69501941_2416459841958633_8338188077537165312_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/69400593_2411827222421895_4395387788908822528_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/67925153_2411298762474741_8196892982167732224_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/68633829_2410224625915488_2510518150991183872_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:57.560Z",
        updatedAt: "2024-04-22T12:24:57.560Z",
      },
      {
        id: "ff1b47d1-bddf-46c6-8a39-2152d87c478e",
        name: "Ski",
        city: 41,
        phone: "+994502904333",
        latitude: 41.03357551118856,
        longitude: 47.886837984658015,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/430886358_454752806879362_520785276005016516_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/428358310_441235818231061_7527026784287096782_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/432026830_457869676567675_5764667216684864732_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/432016131_457869563234353_4186749515429917977_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:58.620Z",
        updatedAt: "2024-04-22T12:24:58.620Z",
      },
      {
        id: "d285fd8e-9976-4abe-8161-ea4dafa94c1c",
        name: "Airsoft",
        city: 8,
        phone: "+994552151189",
        latitude: 40.41979133767852,
        longitude: 49.891064677264126,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/2.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/3.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/4.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:58.824Z",
        updatedAt: "2024-04-22T12:24:58.824Z",
      },
      {
        id: "4cd1b294-bd0a-4cee-a802-9392ea9777a8",
        name: "Paragliding",
        city: 63,
        phone: "+994508709676",
        latitude: 40.931185837978,
        longitude: 49.23547115396983,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/315441980_516245133851566_3244794267270162408_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/348568435_6613754561969237_5023243877282647426_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/306080169_463588869117193_8414068152784025366_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/315442982_516245140518232_6253017973092592291_n.jpg",
        ],
        rate: 3.5,
        rateCount: 2,
        status: true,
        createdAt: "2024-04-22T12:25:00.175Z",
        updatedAt: "2024-05-03T11:59:29.866Z",
      },
    ],
    cities: [
      "Aghjabadi",
      "Aghdam",
      "Aghdash",
      "Aghdara",
      "Aghstafa",
      "Aghsu",
      "Astara",
      "Babak",
      "Baku",
      "Balakan",
      "Beylagan",
      "Barda",
      "Bilasuvar",
      "Jabrayil",
      "Jalilabad",
      "Julfa",
      "Dashkasan",
      "Dalimammadli",
      "Fuzuli",
      "Gadabay",
      "Ganja",
      "Goranboy",
      "Goychay",
      "Goygol",
      "Goytepe",
      "Hajigabul",
      "Horadiz",
      "Khachmaz",
      "Khankendi",
      "Khojaly",
      "Khojavend",
      "Khizi",
      "Khirdalan",
      "Khudat",
      "Imishli",
      "Ismayilli",
      "Kalbajar",
      "Kurdamir",
      "Kangarli",
      "Gakh",
      "Gazakh",
      "Gabala",
      "Gobustan",
      "Govlar",
      "Guba",
      "Gubadli",
      "Gusar",
      "Lachin",
      "Lerik",
      "Lankaran",
      "Liman",
      "Masalli",
      "Mingachevir",
      "Naftalan",
      "Nakhchivan",
      "Neftchala",
      "Oghuz",
      "Ordubad",
      "Saatli",
      "Sabirabad",
      "Salyan",
      "Samukh",
      "Sadarak",
      "Siyazan",
      "Sumgait",
      "Shabran",
      "Shahbuz",
      "Shamakhi",
      "Sheki",
      "Shamkir",
      "Sharur",
      "Shirvan",
      "Shusha",
      "Terter",
      "Tovuz",
      "Ujar",
      "Yardimli",
      "Yevlakh",
      "Zagatala",
      "Zangilan",
      "Zardab",
      "Mashtaga",
      "Gobu",
      "Buzovna",
      "Gala",
      "Balakhani",
      "Zigh",
      "Bina",
      "Zira",
      "Shuvelan",
      "Turkan",
      "Kurdekhani",
      "Zabrat",
      "Keshla",
      "Amirjan",
      "Bilgah",
      "Jorat",
      "Bulbule",
      "Bibiheybat",
      "Bilajari",
      "Masazir",
      "Digah",
      "Yeni Ramana",
      "Ramana",
      "Pirshagi",
      "Pirallahi",
      "Mardakan",
      "Shagan",
      "Dubendi",
      "Hokmali",
      "Hovsan",
      "Sahil / Primorsk",
      "Alat",
      "Badamdar",
      "Lokbatan",
      "Goredil",
      "Fatmai",
      "Nardaran",
      "Novkhani",
      "Saray",
      "Yeni Surakhani",
      "Mehdiabad",
      "Sulutepe",
      "Garachukhur",
      "Yeni Gunashli",
      "Bakikhanov",
      "Ahmedli Metro",
      "Khalklar Metro",
      "Neftchilar Metro",
      "Garayev Metro",
      "Koroglu Metro",
      "Ulduz Metro",
      "Bakmil Metro",
      "Narimanov Metro",
      "Ganjlik Metro",
      "28 May Metro",
      "Khatai Metro",
      "Sahil Metro",
      "Icherisheher Metro",
      "Nizami Metro",
      "Elmler Metro",
      "Inshaatchilar Metro",
      "20 January Metro",
      "Ajami Metro",
      "Nasimi Metro",
      "Azadlig Metro",
      "Darnagul Metro",
      "8 November Metro",
      "Avtovagzal Metro",
      "Khojasan Metro",
    ],
    comment: "review",
    road: "Road",
    fromCar: "Pick-up location",
  },
  RU: {
    sloqan: "Езжайте экономичным путем!",
    from: "Откуда",
    to: "Куда",
    language: "Язык",
    download: "Скачать приложение!",
    infoPart1: "Мы к вашим услугам по низким ценам",
    infoPart2: "Вы можете доверят нам",
    infoPart3: "Прокрутите, найдите, нажмите и вперед!",
    infoDesc1:
      "Независимо от того, куда вы направляетесь, вы найдете среди широкого спектра направлений и маршрутов идеальное путешествие по низким ценам.",
    infoDesc2:
      "Мы уделяем время тому, чтобы познакомиться с каждым из наших членов и партнеров. Мы проверяем отзывы, профили и идентификаторы, чтобы вы знали, с кем путешествуете, и удобно бронировали поездку на нашей безопасной платформе.",
    infoDesc3:
      "Бронирование путешествий никогда не было таким простым! Благодаря нашему простому приложению, оснащенному отличными технологиями, вы сможете забронировать поездку рядом с вами за считанные минуты.",
    sloqan2: "Путешествуйте вместе, сокращайте расходы!",
    appOnPlatforms: "Приложение Gezz теперь доступно на IOS и Android.",
    address: "Адрес:",
    addressDetails: "Баку, Ислама Абишова, 104",
    phone: "Контактный номер:",
    phoneDetails: "+994 55 254 40 40",
    email: "Электронная почта:",
    emailDetails: "info@gezz.az",
    copyright1: "© 2024 Все авторские права принадлежат Gezz",
    copyright2: "Политика конфиденциальности",
    copyright3: "Положения и условия",
    faq: "FAQ",
    howToUseIt: "Как использовать?",
    whatIs: "Что такое Gezz?",
    aboutUs: "О нас",
    blog: "Блог",
    actions: "Активность",
    restourants: "Рестораны",
    otels: "Отели",
    travels: "Поездки",
    usersThrouts: "Отзывы наших пользователей",
    feedbacks: [
      {
        id: 1,
        stars: 5,
        date: "17 января 2024 г.",
        desc: "Отличная идея и программа. Если он станет известным, это будет большим удобством для людей. И пассажир, и водитель.",
        name: "Симрал",
        title: "item #1",
      },
      {
        id: 2,
        stars: 5,
        date: "7 декабря 2023 г.",
        desc: "Пользоваться должен каждый – и водитель, и участник дорожного движения.",
        name: "Джавид Мирзеев",
        title: "item #1",
      },
      {
        id: 3,
        stars: 5,
        date: "14 января 2023 г.",
        desc: "Очень супер! Когда идешь куда-то, вместо 20 манатов отдаешь 5 манатов.",
        name: "Иззет Гулелиев",
        title: "item #1",
      },
      {
        id: 4,
        stars: 5,
        date: "17 января 2024 г.",
        desc: "Отличная идея и программа. Если он станет известным, это будет большим удобством для людей. И пассажир, и водитель.",
        name: "Симрал",
        title: "item #1",
      },
      {
        id: 5,
        stars: 5,
        date: "7 декабря 2023 г.",
        desc: "Пользоваться должен каждый – и водитель, и участник дорожного движения.",
        name: "Джавид Мирзеев",
        title: "item #1",
      },
      {
        id: 6,
        stars: 5,
        date: "14 января 2023 г.",
        desc: "Очень супер! Когда идешь куда-то, вместо 20 манатов отдаешь 5 манатов.",
        name: "Иззет Гулелиев",
        title: "item #1",
      },
    ],
    yourSecurityTile: "Ваша безопасность очень важна для нас",
    yourSecurityDesc:
      "В Gezz мы прилагаем все усилия, чтобы сделать нашу платформу максимально безопасной. Но мы хотим, чтобы вы точно знали, как избежать мошенничества и как сообщить о нем, когда оно произойдет. Следуйте нашим советам, чтобы обезопасить себя.",
    yourSecurityMore: "Узнать больше",
    aboutTile: "О нас Gezz",
    aboutDesc: [
      "Gezz — это платформа для путешествий.",
      "Здесь вы можете найти пользователей, которые едут в различные районы, и путешествовать вместе, разделяя дорожные расходы.",
      "Вы можете открыть для себя рестораны/гостиницы/развлечения в различных городах с помощью Gezz.",
      "Если вам нужна машина для поездки, вы можете просмотреть машины в аренду в приложении Gezz."
    ],
    aboutMore: "Скачайте приложение и наслаждайтесь путешествием!",
    carPoolingTitle: "Карпулинг означает движение вместе 🌱",
    carPoolingDesc:
      "Помимо ваших личных выгод, вы принимаете меры, чтобы сделать мобильность более простой и разумной. Компания Gezz предотвращает выбросы CO2 с момента своего создания:",
    carPoolingDesc2:
      "Поделитесь своим путешествием с Gezz и уменьшите воздействие на окружающую среду.",
    carPoolingButton: "Скачайте Gezz",
    carPoolingCards: [
      {
        icon: "🌲",
        desc: "Каждый день — это посадка дерева.",
      },
      {
        icon: "💧",
        desc: "Означает неизрасходованная питьевая вода.",
      },
      {
        icon: "🕝",
        desc: "Эквивалент потребления 1 Азербайджанца за сутки",
      },
    ],
    howItsWorkTitle: "Как это работает?",
    howItsWorkDesc:
      "Gezz — это не просто платформа для обмена поездками, это инновационное приложение для путешествий. Это позволяет вам делиться своими поездками, организовывать их в последнюю минуту или заранее. Кроме того, это поможет вам найти альтернативы, такие как отели, рестораны и развлечения в ваших путешествиях.",
    howItsWorkCards: [
      {
        number: "01",
        title: "Загрузите приложение Gezz",
        description:
          "Gezz позаботится о том, чтобы найти для вас туристические предложения, соответствующие вашим привычкам, поэтому вам не придется ничего делать! Карос понимает вас и предлагает наиболее подходящие карпулинги с учетом ваших привычек.",
      },
      {
        number: "02",
        title: "Выберите предложение, которое подходит вам лучше всего",
        description:
          "Вы можете отправить запрос на объявления водителей-пассажиров или пассажиров-водителей.",
      },
      {
        number: "03",
        title: "Подтвердите в 2 клика!",
        description:
          "Выберите поездку, которая вам подходит, и забронируйте ее бесплатно. Вы также можете воспользоваться многими другими функциями, такими как обмен сообщениями в приложении, согласование цен и приоритет в поездках.",
      },
    ],
    everyDayGrow: "Сообщество, которое растет каждый день",
    usersCountName: "пользователей",
    advantagesTitle: "Откройте для себя свои сильные стороны",
    advantagesDesc:
      "С Gezz мы даем возможность экономить время и деньги, знакомиться с новыми людьми и защищать окружающую среду!",
    advantagesCards: [
      {
        person: "Пасажир",
        desc: "Путешествуйте с комфортом по цене автобуса 🚌",
        price: "0",
        currency: "AZN",
        priceDesc: "с ценами от",
        more: "Больше преимуществ >",
        image: "https://gezz.az/assets/firstImage.png",
      },
      {
        person: "Водитель",
        desc: "Экономьте деньги на бензине 💸",
        price: "+ 200",
        currency: "AZN",
        priceDesc: "до дополнительного заработка",
        more: "Больше преимуществ >",
        image: "https://gezz.az/assets/secondImage.png",
      },
    ],
    DivisPartTitle1: "Езжайте вместе",
    DivisPartTitle2: "и ",
    DivisPartTitle3: "экономьте",
    DivisPartDesc:
      "Приложение № 1 для удобных, доступных и увлекательных поездок",
    DivisPartButton: "Скачать приложение",
    searchTypes: [
      {
        id: 0,
        name: "Поездки",
        image: [
          "https://gezz.az/assets/riderWhite.svg",
          "https://gezz.az/assets/riderBlack.svg",
        ],
      },
      {
        id: 1,
        name: "Машины",
        image: [
          "https://gezz.az/assets/carWhite.png",
          "https://gezz.az/assets/carBlack.png",
        ],
      },
      {
        id: 2,
        name: "Рестораны",
        image: [
          "https://gezz.az/assets/restaurantWhite.svg",
          "https://gezz.az/assets/restaurantBlack.svg",
        ],
      },
      {
        id: 3,
        name: "Отели",
        image: [
          "https://gezz.az/assets/otelWhite.svg",
          "https://gezz.az/assets/otelBlack.svg",
        ],
      },
      {
        id: 4,
        name: "Активности",
        image: [
          "https://gezz.az/assets/activityWhite.svg",
          "https://gezz.az/assets/activityBlack.svg",
        ],
      },
    ],
    search: "Поиск",
    searchNames: [
      "Поиск поездок или города",
      "Поиск машин",
      "Поиск ресторанов или города",
      "Поиск отелей или города",
      "Поиск активностей или города",
    ],
    searchNamesNotFound: [
      "К сожалению, поездок, соответствующий вашему запросу, не найден.",
      "К сожалению, машин, соответствующий вашему запросу, не найден.",
      "К сожалению, ресторан, соответствующий вашему запросу, не найден.",
      "К сожалению, отелей, соответствующий вашему запросу, не найден.",
      "К сожалению, активностей, соответствующий вашему запросу, не найден.",
    ],
    restourantsCards: [
      {
        id: "90fa6dae-ce55-4e0d-9ab0-2f437aef5e88",
        name: "Elite ресторан",
        city: 69,
        phone: "+994514305758",
        latitude: 40.8379832,
        longitude: 46.034589,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10839982.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10583026.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10547512.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10818466.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:22.455Z",
        updatedAt: "2024-04-22T10:49:22.455Z",
      },
      {
        id: "db211c28-331a-4e8b-97a1-0754b7409f76",
        name: "Ресторан Ширхан",
        city: 19,
        phone: "+994506838552",
        latitude: 40.6693287,
        longitude: 45.823086599999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10732543.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10296600.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10702488.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10174414.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:24.826Z",
        updatedAt: "2024-04-22T10:49:24.826Z",
      },
      {
        id: "083b4066-5970-46a2-9a04-12c450f89b89",
        name: "Замок Нарин",
        city: 68,
        phone: "+994505655708",
        latitude: 40.9243449,
        longitude: 46.160612,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10480283.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10613888.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10858569.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10043608.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:24.978Z",
        updatedAt: "2024-04-22T10:49:24.978Z",
      },
      {
        id: "25aa4399-e9bf-4bae-81eb-d3eb13a9ba05",
        name: "Ресторан Гала",
        city: 69,
        phone: "+994503105711",
        latitude: 40.794785399999995,
        longitude: 46.079800399999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10291408.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10896051.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10741049.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10657594.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:26.213Z",
        updatedAt: "2024-04-22T10:49:26.213Z",
      },
      {
        id: "6c0da42d-9ae7-45f0-8685-9b22bcb953e8",
        name: "Артиллерийский ресторан",
        city: 35,
        phone: "+994552898999",
        latitude: 40.8811574,
        longitude: 48.069077899999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10747024.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10760497.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10557546.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10350139.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:26.635Z",
        updatedAt: "2024-04-22T10:49:26.635Z",
      },
      {
        id: "0fcf41c7-4020-4d2e-8946-30ad28eafa35",
        name: "Melham ресторан",
        city: 67,
        phone: "+994508806060",
        latitude: 40.6888971,
        longitude: 48.6377373,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10720851.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10285355.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10342549.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10702998.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:26.914Z",
        updatedAt: "2024-04-22T10:49:26.914Z",
      },
      {
        id: "fca43cc3-766f-4a3d-aa97-63358930691e",
        name: "Зеленый ресторан",
        city: 0,
        phone: "+994504605759",
        latitude: 40.0387139,
        longitude: 47.4545495,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10226491.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10607073.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10678683.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10875018.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:27.540Z",
        updatedAt: "2024-04-22T10:49:27.540Z",
      },
      {
        id: "a83a75ad-4ad4-4f91-95e6-fd73d811a71f",
        name: "Mountain Breeze",
        city: 44,
        phone: "+994519993999",
        latitude: 41.2324722,
        longitude: 48.3039133,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10428679.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10221857.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10028030.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10215119.jpeg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:28.225Z",
        updatedAt: "2024-04-22T10:49:28.225Z",
      },
      {
        id: "43d4c8ca-f3e7-423d-a31a-5559a722e610",
        name: "Стамбул Ресторан Казахской кухни",
        city: 40,
        phone: "+994708005959",
        latitude: 41.0900585,
        longitude: 45.358627999999996,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10040458.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10378458.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10134297.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10315137.jpeg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:28.373Z",
        updatedAt: "2024-04-22T10:49:28.373Z",
      },
      {
        id: "66e51461-98b9-4eea-bc11-f751d900f19f",
        name: "Ресторан Чанлибел",
        city: 27,
        phone: "+994517172222",
        latitude: 41.4855261,
        longitude: 48.7762076,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10422249.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10493813.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10336291.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10191671.jpeg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:29.271Z",
        updatedAt: "2024-04-22T10:49:29.271Z",
      },
      {
        id: "437cf249-d8c8-4d96-b408-4ba92350b0e8",
        name: "Ресторан Абкора",
        city: 67,
        phone: "+994502950212",
        latitude: 40.6450672,
        longitude: 48.5842208,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10424984.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10277150.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10430604.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10257791.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T10:49:30.329Z",
        updatedAt: "2024-04-22T10:49:30.329Z",
      },
      {
        id: "1a0fa0aa-20c3-4a74-a51f-279bb2301f93",
        name: "Ресторан Пальма Губа",
        city: 44,
        phone: "+994508975989",
        latitude: 41.3676148,
        longitude: 48.5352639,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10011410.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10183889.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10025517.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10112819.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:30.529Z",
        updatedAt: "2024-04-30T13:31:14.149Z",
      },
      {
        id: "dddbf5bc-eadc-4659-b3f3-fc9e03e8b371",
        name: "Ресторан Таракама",
        city: 34,
        phone: "+994513776777",
        latitude: 39.9304741,
        longitude: 48.00686830000001,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10789288.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10512772.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10356903.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10122368.jpeg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:28.715Z",
        updatedAt: "2024-05-06T05:07:47.001Z",
      },
      {
        id: "985005e2-c26b-4531-8fe3-c5b5fe1cf3c7",
        name: "Ресторан Зеленое озеро",
        city: 4,
        phone: "+994504842424",
        latitude: 41.220368199999996,
        longitude: 45.4349638,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10135658.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10381655.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10498631.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10143056.jpeg",
        ],
        rate: 4,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:27.469Z",
        updatedAt: "2024-05-10T13:58:35.493Z",
      },
      {
        id: "53dd58fa-f28d-4773-bacf-420f654b8bb4",
        name: "Ресторан Фонтан Набран",
        city: 27,
        phone: "+994505759235",
        latitude: 41.777536399999995,
        longitude: 48.6633065,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10611713.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10247028.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10221232.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10671914.jpg",
        ],
        rate: 0.5,
        rateCount: 2,
        status: true,
        createdAt: "2024-04-22T10:49:30.781Z",
        updatedAt: "2024-04-30T13:29:32.244Z",
      },
      {
        id: "a6f5e545-ab2c-4569-b0e4-e84cf196acb7",
        name: "Ресторан Хидир",
        city: 46,
        phone: "+994504482323",
        latitude: 41.4106089,
        longitude: 48.401641,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10880447.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10361837.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10241919.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10478401.jpg",
        ],
        rate: 4,
        rateCount: 2,
        status: true,
        createdAt: "2024-04-22T10:49:32.425Z",
        updatedAt: "2024-05-06T03:32:39.684Z",
      },
      {
        id: "b6187215-2c37-47b7-ad8f-1706e2fb18b9",
        name: "Ресторан на озере Нохур",
        city: 41,
        phone: "+994552659432",
        latitude: 40.9552534,
        longitude: 47.87823890000001,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10258313.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10428311.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10596977.jpeg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10808317.jpeg",
        ],
        rate: 4,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T10:49:29.440Z",
        updatedAt: "2024-04-25T12:23:43.083Z",
      },
      {
        id: "abb0d47f-ac53-4034-98fb-ecee94df9ece",
        name: "Хуторок Гусар",
        city: 46,
        phone: "+994505252151",
        latitude: 41.4038099,
        longitude: 48.3854341,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10252168.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10183088.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10576599.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/rest-10862090.jpg",
        ],
        rate: 4.285714285714286,
        rateCount: 7,
        status: true,
        createdAt: "2024-04-22T10:49:32.730Z",
        updatedAt: "2024-05-14T04:52:19.674Z",
      },
    ],
    otelsCards: [
      {
        id: "a0aa7d2f-bcf6-4849-95ce-8899c085b25e",
        name: "Отель Хан Ленкорань",
        city: 49,
        phone: "+994503905446",
        latitude: 38.779424690662836,
        longitude: 48.8250614,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10567650.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10848666.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10173210.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10011301.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:20.026Z",
        updatedAt: "2024-04-22T11:55:20.026Z",
      },
      {
        id: "48950a4d-cfe0-4c80-b34d-ff5ff46fa026",
        name: "Парк Шалат, Шахдаг",
        city: 46,
        phone: "+994123102020",
        latitude: 41.32026984896755,
        longitude: 48.134211915343386,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10704824.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10753711.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10824391.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10850483.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:19.502Z",
        updatedAt: "2024-04-22T11:55:19.502Z",
      },
      {
        id: "756e90fa-d71a-45f5-8c0e-4d97a9817f55",
        name: "Иссам Отель & Спа",
        city: 68,
        phone: "+994242448781",
        latitude: 41.204543570106495,
        longitude: 47.1749989460326,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10596389.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10251244.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10496999.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10099957.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:19.811Z",
        updatedAt: "2024-04-22T11:55:19.811Z",
      },
      {
        id: "d9490bc4-ff9c-4a3a-a819-56a932ce81cc",
        name: "Горный курорт Туфандаг",
        city: 41,
        phone: "+994123101191",
        latitude: 41.03381465594725,
        longitude: 47.887361492502,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10359779.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10556229.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10672348.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10307270.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:20.597Z",
        updatedAt: "2024-04-22T11:55:20.597Z",
      },
      {
        id: "e9363c54-1b35-43cb-8f39-c0f9996b8156",
        name: "Отель Вива Габала",
        city: 41,
        phone: "+994503111051",
        latitude: 40.9992659637207,
        longitude: 47.8634157544908,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10861056.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10241381.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10584061.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10486965.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.522Z",
        updatedAt: "2024-04-22T11:55:21.522Z",
      },
      {
        id: "b5664cc2-c110-4870-bdde-b234480b8de5",
        name: "Арена Гранд Отель",
        city: 44,
        phone: "+994102607646",
        latitude: 41.36303939945925,
        longitude: 48.5654852539673,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10391903.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10169242.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10674655.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10086862.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.629Z",
        updatedAt: "2024-04-22T11:55:21.629Z",
      },
      {
        id: "275b2465-7a9c-4b5d-bed3-97cddbda196b",
        name: "Шахдаг Отель & Спа",
        city: 46,
        phone: "+994123101110",
        latitude: 41.32005214483496,
        longitude: 48.1367733460301,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10482096.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10488129.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10362000.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10015095.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.821Z",
        updatedAt: "2024-04-22T11:55:21.821Z",
      },
      {
        id: "3c12a282-57b3-47fa-af22-24f3899a808d",
        name: "Отель Куба Палас",
        city: 44,
        phone: "+994992550055",
        latitude: 41.36446686361403,
        longitude: 48.497945272117434,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10110489.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10178644.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10627977.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10241357.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.042Z",
        updatedAt: "2024-04-22T11:55:23.042Z",
      },
      {
        id: "074d183c-c6c3-4aa5-b3a8-a36451ff71e5",
        name: "Эксельсиор Отель Шамкир",
        city: 69,
        phone: "+994552085700",
        latitude: 40.83811193240524,
        longitude: 46.02514095767095,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10434880.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10434671.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10695877.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10526322.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.181Z",
        updatedAt: "2024-04-22T11:55:23.181Z",
      },
      {
        id: "5e1b08ea-7991-47ca-8427-4a0e4aff65cc",
        name: "Премиум Отель Гянджа",
        city: 20,
        phone: "+994222668002",
        latitude: 40.6921454412308,
        longitude: 46.36684613068844,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10058531.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10632021.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10572098.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10245525.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:22.908Z",
        updatedAt: "2024-04-30T07:49:49.155Z",
      },
      {
        id: "f92fb6c3-2962-4dcb-aa62-7e1aaf45f8dd",
        name: "ВИС Палм Отель Гянджа",
        city: 20,
        phone: "+994222557755",
        latitude: 40.696362381776154,
        longitude: 46.376788369311576,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10477309.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10653983.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10059513.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10293437.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:21.884Z",
        updatedAt: "2024-04-22T11:55:21.884Z",
      },
      {
        id: "0d449c2a-6f7e-4f51-9e26-dc15dbde644b",
        name: "ILK INN Hotel",
        city: 46,
        phone: "+994508770038",
        latitude: 41.4229154114882,
        longitude: 48.42545012327892,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10237578.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10497412.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10376992.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10585296.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:22.156Z",
        updatedAt: "2024-04-22T11:55:22.156Z",
      },
      {
        id: "b4bf1b3e-47bd-4867-9c72-7286c153db38",
        name: "Отель Чинар и СПА Нафталан",
        city: 53,
        phone: "+994223521000",
        latitude: 40.50031887867114,
        longitude: 46.82033121534421,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10346398.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10161574.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10342637.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10437358.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:22.872Z",
        updatedAt: "2024-04-22T11:55:22.872Z",
      },
      {
        id: "8376fefc-ae77-431e-9527-64b241b1a029",
        name: "Аян Палас Отель",
        city: 74,
        phone: "+994502299009",
        latitude: 40.9892940041353,
        longitude: 45.6445344719021,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10427018.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10379501.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10388571.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10858711.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:22.945Z",
        updatedAt: "2024-04-22T11:55:22.945Z",
      },
      {
        id: "b31e1e18-12b0-4cb5-a70e-81565fb77e74",
        name: "Велнесс Резорт Ланкаран Спрингс",
        city: 49,
        phone: "+994512359600",
        latitude: 38.7678686901812,
        longitude: 48.7605465539673,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10200214.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10017001.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10080458.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10245687.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.185Z",
        updatedAt: "2024-04-22T11:55:23.185Z",
      },
      {
        id: "fd61eb86-703e-46aa-87bf-ac108fae4c90",
        name: "Afra Hotel",
        city: 56,
        phone: "+994554448001",
        latitude: 41.066667883298706,
        longitude: 47.46921779630336,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10430575.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10438305.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10066831.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10593006.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:23.775Z",
        updatedAt: "2024-04-22T11:55:23.775Z",
      },
      {
        id: "48a4dda0-97a1-4acc-9ac8-d77d7d05d131",
        name: "Гах Эл Резорт Отель",
        city: 39,
        phone: "+994704455202",
        latitude: 41.43761866336678,
        longitude: 46.9782317883547,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10128237.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10631118.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10139416.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10796895.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:25.194Z",
        updatedAt: "2024-04-22T11:55:25.194Z",
      },
      {
        id: "995d3fa1-c50d-490c-a528-b0da7a23a519",
        name: "ОТЕЛЬ И СПА ГАЛААЛТЫ",
        city: 63,
        phone: "+994125040909",
        latitude: 41.090036889923304,
        longitude: 48.93729562698716,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10736668.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10794163.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10742653.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10482155.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:25.391Z",
        updatedAt: "2024-04-22T11:55:25.391Z",
      },
      {
        id: "5eeff7a6-706a-4d9b-8c80-c7c401265d40",
        name: "River Inn Boutique Hotel",
        city: 27,
        phone: "+994552150193",
        latitude: 41.778411255144654,
        longitude: 48.67038445952448,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10812818.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10121697.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10538597.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10586331.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:25.816Z",
        updatedAt: "2024-04-22T11:55:25.816Z",
      },
      {
        id: "631b4ed9-336f-4439-a1c5-315e8908d2e9",
        name: "Старый город Шеки",
        city: 68,
        phone: "+994514666234",
        latitude: 41.20095427172368,
        longitude: 47.1868375306884,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10777404.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10881024.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10296713.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10456013.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T11:55:29.076Z",
        updatedAt: "2024-04-22T11:55:29.076Z",
      },
      {
        id: "a0628ef4-7628-4a7e-9996-9e09a9432b7a",
        name: "Vogue Hotel Набран",
        city: 27,
        phone: "+994502633333",
        latitude: 41.70508623717405,
        longitude: 48.73793077672107,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10318253.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10221725.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10137920.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10383227.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:23.457Z",
        updatedAt: "2024-05-12T14:45:42.181Z",
      },
      {
        id: "3218c057-b83f-4b9a-afe0-ae57820ec387",
        name: "Гей Гёль Lake Resort",
        city: 23,
        phone: "+994512296477",
        latitude: 40.58753352169726,
        longitude: 46.31582895767095,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10391504.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10473005.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10453650.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10809232.jpg",
        ],
        rate: 1,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:34.104Z",
        updatedAt: "2024-04-30T13:35:48.392Z",
      },
      {
        id: "5fde5403-fe74-4eb2-a04b-2466fb3f815b",
        name: "Шамахинский дворец Шердил",
        city: 67,
        phone: "+994125040909",
        latitude: 40.62603963703284,
        longitude: 48.46919538465661,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10844581.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10833099.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10406762.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/otel-10064297.jpg",
        ],
        rate: 5,
        rateCount: 1,
        status: true,
        createdAt: "2024-04-22T11:55:25.177Z",
        updatedAt: "2024-05-12T19:35:57.827Z",
      },
    ],
    activitiesCards: [
      {
        id: "00e6917e-7d14-447a-9b7f-172718bed0a9",
        name: "Стрельба из лука",
        city: 46,
        phone: "+994123101110",
        latitude: 41.31939138050239,
        longitude: 48.136623138682374,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/энвато19.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/Archery.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/thumb__536_237_0_0_auto.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/thumb__536_237_0_0_auto.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:55.533Z",
        updatedAt: "2024-04-22T12:24:55.533Z",
      },
      {
        id: "6c3c6774-b9db-40f0-be1c-af6c14a9ef0f",
        name: "Тур на квадроцикле",
        city: 41,
        phone: "+994502904333",
        latitude: 41.03357551118855,
        longitude: 47.886837984658015,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/jiSCQHJUVSW.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/2y1ge8ycf.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/JBgac.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/ZNXOSAC.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:54.969Z",
        updatedAt: "2024-04-22T12:24:54.969Z",
      },
      {
        id: "33c09705-f7d9-4f4c-be71-9b5158b6bc87",
        name: "Стрельба",
        city: 8,
        phone: "+994774095854",
        latitude: 40.40937583448257,
        longitude: 49.83803979643032,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/302673137_387471783533786_1956829846892196929_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/aHSDIUAgdi.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1465857454_b69729df-e498-45bf-89d4-55f25fce76011.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/a3.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:55.862Z",
        updatedAt: "2024-04-22T12:24:55.862Z",
      },
      {
        id: "819d2c43-28e0-4d92-a239-10f940bb39cb",
        name: "Дворец Шеки-хана",
        city: 68,
        phone: "+",
        latitude: 41.2049585931624,
        longitude: 47.197843676709915,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/original.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/image139.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/Şəki_xan_sarayı.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/_1564090113.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:55.866Z",
        updatedAt: "2024-04-22T12:24:55.866Z",
      },
      {
        id: "c1ce2b1d-66cc-40d9-b5ab-40b588f46533",
        name: "Лагичский историко-географический музей",
        city: 35,
        phone: "+994202877579",
        latitude: 40.84609882135035,
        longitude: 48.38088086136793,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/Музей_Лагич.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/images.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/az.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/6200.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.072Z",
        updatedAt: "2024-04-22T12:24:56.072Z",
      },
      {
        id: "5b2da2e6-9cb1-4660-a450-978c1749283e",
        name: "Стрельба",
        city: 46,
        phone: "+994123101110",
        latitude: 41.31939138050238,
        longitude: 48.136623138682374,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/7c8c6184-9840-4c98-bd7b-0089806c5995.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1-1-1042x460.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1-1-4.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/DSC_3262-1042x460.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.651Z",
        updatedAt: "2024-04-22T12:24:56.651Z",
      },
      {
        id: "7ab4dc0e-6eb1-41e7-aa99-e60cc459b5eb",
        name: "Картинг",
        city: 8,
        phone: "+994552773303",
        latitude: 40.420332422986874,
        longitude: 49.8545374920519,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/273584034_4853590738027648_1821989458576670365_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/305571021_442724404545135_8062463216197273059_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/274757158_4909460099107378_6774754324889914171_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/272258674_4791170404269682_3378642945395299124_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.675Z",
        updatedAt: "2024-04-22T12:24:56.675Z",
      },
      {
        id: "fb9db632-9012-4444-81b2-b12c3d7beae2",
        name: "Лерикский музей долголетия",
        city: 48,
        phone: "+",
        latitude: 38.7771375854217,
        longitude: 48.41951712329,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/119033421_635927330399635_8702102511087232637_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/118953534_635927337066301_9195035406779080653_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/119053681_635927327066302_7626096416254120685_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/119056895_635927333732968_6840896585176596118_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:56.858Z",
        updatedAt: "2024-04-22T12:24:56.858Z",
      },
      {
        id: "96fa82d9-1143-4827-af8f-effa33a74988",
        name: "Гольф",
        city: 44,
        phone: "+994992550055",
        latitude: 41.33335571677116,
        longitude: 48.5126209,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347249959_228925656432162_61673056183458145_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347261578_1675367056246469_6987559658485070502_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347437908_237956288925549_5580597315769921114_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/347226756_983007866478397_8384600699586982265_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:57.125Z",
        updatedAt: "2024-04-22T12:24:57.125Z",
      },
      {
        id: "1f8e88e5-a638-4e91-9bf7-f6eede987d6b",
        name: "Zipline",
        city: 68,
        phone: "+994518788574",
        latitude: 41.19762058241731,
        longitude: 47.15711336751136,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/69501941_2416459841958633_8338188077537165312_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/69400593_2411827222421895_4395387788908822528_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/67925153_2411298762474741_8196892982167732224_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/68633829_2410224625915488_2510518150991183872_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:57.560Z",
        updatedAt: "2024-04-22T12:24:57.560Z",
      },
      {
        id: "ff1b47d1-bddf-46c6-8a39-2152d87c478e",
        name: "Лыжи",
        city: 41,
        phone: "+994502904333",
        latitude: 41.03357551118856,
        longitude: 47.886837984658015,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/430886358_454752806879362_520785276005016516_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/428358310_441235818231061_7527026784287096782_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/432026830_457869676567675_5764667216684864732_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/432016131_457869563234353_4186749515429917977_n.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:58.620Z",
        updatedAt: "2024-04-22T12:24:58.620Z",
      },
      {
        id: "d285fd8e-9976-4abe-8161-ea4dafa94c1c",
        name: "Страйкбол",
        city: 8,
        phone: "+994552151189",
        latitude: 40.41979133767852,
        longitude: 49.891064677264126,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/1.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/2.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/3.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/4.jpg",
        ],
        rate: 0,
        rateCount: 0,
        status: true,
        createdAt: "2024-04-22T12:24:58.824Z",
        updatedAt: "2024-04-22T12:24:58.824Z",
      },
      {
        id: "4cd1b294-bd0a-4cee-a802-9392ea9777a8",
        name: "Парапланеризм",
        city: 63,
        phone: "+994508709676",
        latitude: 40.931185837978,
        longitude: 49.23547115396983,
        photos: [
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/315441980_516245133851566_3244794267270162408_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/348568435_6613754561969237_5023243877282647426_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/306080169_463588869117193_8414068152784025366_n.jpg",
          "https://eu2.contabostorage.com/410611a23efd4162a7f4d561081be4f2:gezz/315442982_516245140518232_6253017973092592291_n.jpg",
        ],
        rate: 3.5,
        rateCount: 2,
        status: true,
        createdAt: "2024-04-22T12:25:00.175Z",
        updatedAt: "2024-05-03T11:59:29.866Z",
      },
    ],
    cities: [
      "Агджабеди",
      "Агдам",
      "Агдаш",
      "Агдера",
      "Агстафа",
      "Агсу",
      "Астара",
      "Бабек",
      "Баку",
      "Балакен",
      "Бейлаган",
      "Барда",
      "Биласуар",
      "Джебраил",
      "Джалилбад",
      "Джульфа",
      "Дашкесан",
      "Далимамедли",
      "Физули",
      "Гедабек",
      "Гянджа",
      "Горанбой",
      "Гёйчай",
      "Гёйгёль",
      "Гёйтепе",
      "Гаджигабул",
      "Горадиз",
      "Хачмаз",
      "Ханкенди",
      "Ходжалы",
      "Ходжавенд",
      "Хызы",
      "Хырдалан",
      "Худат",
      "Имишли",
      "Исмаиллы",
      "Кельбаджар",
      "Кюрдамир",
      "Кенгерли",
      "Гах",
      "Газах",
      "Габала",
      "Гобустан",
      "Говлар",
      "Куба",
      "Кубадлы",
      "Гусар",
      "Лачин",
      "Лерик",
      "Ленкорань",
      "Лиман",
      "Масаллы",
      "Мингячевир",
      "Нафталан",
      "Нахичевань",
      "Нефтчала",
      "Огуз",
      "Ордубад",
      "Саатлы",
      "Сабирабад",
      "Сальян",
      "Самух",
      "Садарак",
      "Сиазань",
      "Сумгаит",
      "Шабран",
      "Шахбуз",
      "Шемаха",
      "Шеки",
      "Шамкир",
      "Шарур",
      "Ширван",
      "Шуша",
      "Тертер",
      "Товуз",
      "Уджар",
      "Ярдымлы",
      "Евлах",
      "Закаталы",
      "Зангелан",
      "Зардаб",
      "Маштага",
      "Гобу",
      "Бузовна",
      "Гала",
      "Балаханы",
      "Зых",
      "Бина",
      "Зыра",
      "Шувелян",
      "Туркан",
      "Кюрдаханы",
      "Забрат",
      "Кешля",
      "Амирджан",
      "Бильгя",
      "Джорат",
      "Бюльбюля",
      "Биби-Эйбат",
      "Биладжары",
      "Масазыр",
      "Дига",
      "Йени Рамана",
      "Рамана",
      "Пиршаги",
      "Пираллахи",
      "Мярдякан",
      "Шаган",
      "Дюбенди",
      "Гекмели",
      "Говсан",
      "Сахиль / Приморск",
      "Алят",
      "Бадамдар",
      "Локбатан",
      "Горадиль",
      "Фатмаи",
      "Нардаран",
      "Новханы",
      "Сарай",
      "Йени Сураханы",
      "Мехтиабад",
      "Сулу-тяпя",
      "Гарачухур",
      "Йени Гюнешли",
      "Бакиханов",
      "Ахмедли метро",
      "Халглар метро",
      "Нефтчиляр метро",
      "Гараев метро",
      "Короглу метро",
      "Улдуз метро",
      "Бакмил метро",
      "Нариманов метро",
      "Гянджлик метро",
      "28 Мая метро",
      "Хатаи метро",
      "Сахиль метро",
      "Ичеришехер метро",
      "Низами метро",
      "Элмляр метро",
      "Иншаатчылар метро",
      "20 Января метро",
      "Аджеми метро",
      "Насими метро",
      "Азадлыг метро",
      "Дарнагюль метро",
      "8 Ноября метро",
      "Автовокзал метро",
      "Ходжасан метро",
    ],
    comment: "отзывы",
    road: "Путь",
    fromCar: "Место выдачи",
  },
};
