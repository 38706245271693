import React, { useEffect, useState } from "react";
import "./Footer.css";
import { MdLocationOn, MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { options } from "../../constants";
import PlayStoreLogo from "../HowItsWork/GooglePlayIcon.png";
import AppStoreLogo from "../HowItsWork/AppStoreIcon.png";
import GezzLogo from "./Gezz_logo_w 1.svg";
import InstaLogo from "./insttalogo_svg 1.svg";
import TikTokLogo from "./tiktok_svg.svg";
import FacebookLogo from "./fblogo_svg 1.svg";
import YoutubeLogo from "./ytlogo_svg 1.svg";
import LinkedInLogo from "./linkedin_svg.svg";

const Footer = () => {
  const navigate = useNavigate();
  const [language, setLanguage] = useState(
    localStorage.getItem("languageLabel")
      ? localStorage.getItem("languageLabel")
      : "AZ"
  );
  useEffect(() => {
    setLanguage(
      localStorage.getItem("languageLabel")
        ? localStorage.getItem("languageLabel")
        : "AZ"
    );
  }, [localStorage.getItem("languageLabel")]);
  const scrollToDiv = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth', block:'center' }); 
  }
  return (
    <footer>
      <div className="footerComponent">
        <div className="footerComponent-top">
          <div className="footerComponent-top-left">
            <div
              className="footer-logo-part"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <img
                style={{
                  width: "86px",
                  height: "40px",
                }}
                src={GezzLogo}
                alt="gezz logo"
              />
              <div
                style={{
                  width: "140px",
                  height: "42px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.bidyol&hl=az&gl=AZ"
                  target="_blank"
                  rel="tag"
                >
                  <img
                    style={{
                      width: "72px",
                      height: "22px",
                    }}
                    src={PlayStoreLogo}
                    alt="play store logo"
                  />
                </a>
                <a
                  href="https://apps.apple.com/az/app/gezz/id6456039328"
                  target="_blank"
                  rel="tag"
                >
                  <img
                    style={{
                      width: "64px",
                      height: "22px",
                    }}
                    src={AppStoreLogo}
                    alt="app store logo"
                  />
                </a>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                marginTop: "15px",
              }}
            >
              <span>{options[language].travels}</span>
              <span>{options[language].otels}</span>
              <span>{options[language].restourants}</span>
              <span>{options[language].actions}</span>
              <span>{options[language].blog}</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                marginTop: "15px",
              }}
            >
              <span onClick={() => scrollToDiv("about-content")}>{options[language].aboutUs}</span>
              <span>{options[language].whatIs}</span>
              <span>{options[language].howToUseIt}</span>
              <span>{options[language].faq}</span>
            </div>
          </div>
          <div className="footerComponent-top-right">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <a
                href="https://www.linkedin.com/company/gezz/"
                target="_blank"
                rel="tag"
              >
                <img src={LinkedInLogo} alt="linkedin logo" />
              </a>
              <a
                href="https://www.instagram.com/gezz.az"
                target="_blank"
                rel="tag"
              >
                <img src={InstaLogo} alt="insta logo" />
              </a>
              <a
                href="https://www.tiktok.com/@gezz.az"
                target="_blank"
                rel="tag"
              >
                <img src={TikTokLogo} alt="tiktok logo" />
              </a>
              <a
                href="https://www.facebook.com/gezz.az"
                target="_blank"
                rel="tag"
              >
                <img src={FacebookLogo} alt="facebook logo" />
              </a>
              <a
                href="https://www.youtube.com/@Gezz-AZ"
                target="_blank"
                rel="tag"
              >
                <img src={YoutubeLogo} alt="youtube logo" />
              </a>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <IoCall color="white" size={15} />
              <span>{options[language].phoneDetails}</span>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginTop: "5px",
              }}
            >
              <MdEmail color="white" size={15} />
              <span>{options[language].emailDetails}</span>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginTop: "5px",
              }}
            >
              <MdLocationOn color="white" size={15} />
              <span>{options[language].addressDetails}</span>
            </div>
          </div>
        </div>
        <div className="footerComponent-bottom">
          <span>{options[language]?.copyright1}</span>
          <a
            href="https://gezz.az/privacy.html"
            target="_blank"
            style={{
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <span>{options[language]?.copyright2}</span>
          </a>
          {/* <span>{options[language]?.copyright3}</span> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
