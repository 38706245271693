import React, { useEffect, useState } from 'react'
import './AboutPart.css'
import { options } from '../../constants'
import { useDispatch } from 'react-redux'
import { showMobilePopUpAction } from '../../redux/action'

const AboutPart = () => {
  const dispatch= useDispatch()
  const [language, setLanguage] = useState(
    localStorage.getItem('languageLabel')
      ? localStorage.getItem('languageLabel')
      : 'AZ'
  )
  useEffect(() => {
    setLanguage(
      localStorage.getItem('languageLabel')
        ? localStorage.getItem('languageLabel')
        : 'AZ'
    )
  }, [localStorage.getItem('languageLabel')])
  return (
    <div className='howitswork-part about' id='about-content'>
      <div className='howitswork-content'>
        <div className='about-content-title'>
            <span>{options?.[language]?.aboutTile}</span>
        </div>
        <div className='about-content-description'>
        {options?.[language]?.aboutDesc[0]}<br/>
        {options?.[language]?.aboutDesc[1]}<br/>
        {options?.[language]?.aboutDesc[2]}<br/>
        {options?.[language]?.aboutDesc[3]}<br/>
        </div>
        <div className='about-content-button'>
            <button style={{cursor:'pointer'}} onClick={() => dispatch(showMobilePopUpAction(true))}>{options?.[language]?.aboutMore}</button>
        </div>
      </div>
    </div>
  )
}

export default AboutPart
